import { Grid, InputAdornment, TextField } from "@mui/material";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import { useMeasurementStyles } from "./style";
import { useCallback, useRef, useState } from "react";
import { Box } from "@mui/system";

const Measurements = ({ measurement, title, hide, handleSubmitForm, keys }) => {
  const commonClasses = useStyles();
  const ref = useRef()
  const [state, setState] = useState(true);
  const measurementClasses = useMeasurementStyles();
  const classes = {
    ...commonClasses,
    ...measurementClasses,
  };
  const handleClick = () => {
    setState(false)
    setTimeout(() => {
      ref.current.focus()
    }, 100)
  }
  const handleBlur = useCallback((e) => {
    setState(true)
    if (+e.target?.value && +e.target?.value !== +measurement) {
      handleSubmitForm({ [keys]: e.target.value })
    }
  }, [])


  const handleInputNumber = (e = {}) => {
    const { value } = e.target || {}
    const allowKeys = [...new Array(10).fill().map((x, index) => 96 + index), ...new Array(10).fill().map((x, index) => 48 + index)]
    const index = allowKeys.indexOf(e.keyCode)
    const enteredValue = index > - 1 ? index : ''
    const inputValue = index > -1 ? e.keyCode > 95 ? enteredValue : enteredValue - 10 : '';
    const combinedValue = (value || '') + inputValue
    const number = Object.prototype.toString.call(+combinedValue) === "[object Number]"
    if (![...allowKeys, 8, 9, 13, 37, 39, 46, 190].includes(e.keyCode) || (index > - 1 && number && (+combinedValue < 1 || +combinedValue > 999))) {
      return e.preventDefault()
    }
  }

  return (
    <Grid
      container
      justify="center"
      className={classes.measurementContainer}
    >
      <Grid container direction="column" item xs={12} sm={10}>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.measurement}>
          {state && measurement}
          <TextField
            name={title.toLowerCase()}
            onBlur={handleBlur}
            onKeyDown={handleInputNumber}
            defaultValue={measurement === '-' ? '' : measurement}
            inputProps={{ ref }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{title.toUpperCase() === "WEIGHT" ? "(kg)" : "(cm)"}</InputAdornment>,
            }}
            sx={{
              ...state && { display: 'none' },
              height: '20px',
              '& .MuiInputBase-root': {
                width: '100px',
                '& input': {
                  fontWeight: 600,
                  padding: '0px 8px',
                  height: '20px',
                  border: 'none',
                  background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                  backgroundClip: 'text',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                  '& + .MuiInputAdornment-root': {
                    position: 'absolute',
                    right: 10,
                    '& .MuiTypography-root': {
                      '-webkit-text-fill-color': 'initial',
                      color: 'color(srgb 0.6361 0.6312 0.6314)',
                      paddingRight: '6px',
                      textTransform: 'lowercase',
                      fontSize: '11px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    },
                  }
                },
                height: '20px',
                border: 'none',
              },
            }}
          />
        </Box>
      </Grid>
      <Grid container item justify="flex-end" xs={12} sm={2}>
        <Box className={classes.editIcon} sx={{ display: hide || !state ? 'none' : 'inherit', cursor: 'pointer' }} onClick={handleClick}>
          <img
            src="/assets/img/patient-health/edit.png"
            width={"20px"}
            height={"20px"}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export { Measurements };
