import { createAction } from '@reduxjs/toolkit';

export const loginAction = createAction('auth/login', (credentials) => {
  return {
    payload: credentials
  };
});

export const logoutAction = createAction('auth/logout', (token) => {
    return {
      payload: token,
    };
  });

  export const doctorlogoutAction = createAction('auth/doctor/logout', (token) => {
    return {
      payload: token,
    };
  });

export const doctorLoginAction = createAction('auth/doctor/login', (credentials) => {
  return {
    payload: credentials,
  };
});
export const organizationLoginAction = createAction('auth/organization/login', (credentials) => {
  return {
    payload: credentials,
  };
});

export const resetPasswordAction = createAction('auth/reset/password', (credentials, token) => {
  const payload = {credentials, token }
  return {
    payload: payload
  };
});