import strings from "./strings";

export const userList = [
  {
    label: `${strings.normalUser}`,
    value: "patient",
    img: "/assets/img/Frame21.png",
    isSelected: false,
  },
  {
    label: `${strings.doctor}`,
    value: "doctor",
    img: "/assets/img/Groupimg.png",
    isSelected: false,
  },
  {
    label: `${strings.clinic}`,
    value: "organization",
    img: "/assets/img/hospitali.png",
    isSelected: false,
  },
  {
    label: `${strings.subAdmin}`,
    value: "subadmin",
    img: "/assets/img/jsicon.png",
    isSelected: false,
  },
];

export const HealthParameterList = [
  {
    index: 1,
    primaryKey: "bloodPressureData",
    dataKey: "dia_blood_pressure&sys_blood_pressure",
    primaryHeading: "Blood Pressure",
    secondaryHeading: "Diastolic&Systolic",
    unit: "mmHg",
  },
  {
    index: 2,
    primaryKey: "bloodSugarData",
    dataKey: "blood_sugar",
    primaryHeading: "Blood Sugar",
    secondaryHeading: "Avg. Weekly Blood Sugar",
    unit: "mg/dL",
  },
  {
    index: 3,
    primaryKey: "spo2sData",
    dataKey: "percentage",
    primaryHeading: "Blood Oxygen",
    secondaryHeading: "Weekly SPO2 Range",
    unit: "%",
  },
  {
    index: 4,
    primaryKey: "bodyTemperatureData",
    dataKey: "temperature&fahrenheit",
    primaryHeading: "Body Temperature",
    secondaryHeading: "Celsius&Fahrenheit",
    graphType: "funnelChart",
    unit: "C",
  },
  {
    index: 6,
    primaryKey: "heartRateData",
    dataKey: "pulse",
    primaryHeading: "Heart Rate",
    secondaryHeading: "Avg. Weekly Heart Rate",
    unit: "BPM",
  },
  {
    index: 5,
    primaryKey: "respiratorieData",
    dataKey: "respiratory_rates",
    primaryHeading: "Respiratory Rate",
    secondaryHeading: "Avg. Weekly Rate",
    unit: "Breaths/min",
  },
];

export const LipidProfileList = [
  {
    index: 1,
    primaryHeading: "Triglycerides",
    key: "triglycerides",
    value: 0,
    min: 0,
    max: 500,
    lowerRange: 150,
    upperRange: 200,
  },
  {
    index: 2,
    primaryHeading: "Cholestrol",
    key: "total_cholesterol",
    value: 0,
    min: 0,
    max: 300,
    lowerRange: 200,
    upperRange: 240,
  },
  {
    index: 3,
    primaryHeading: "HDL-C",
    key: "HDL_C",
    value: 0,
    min: 0,
    max: 70,
  },
  {
    index: 4,
    primaryHeading: "VLDL",
    key: "VLDL",
    value: 0,
    min: 0,
    max: 35,
  },
  {
    index: 5,
    primaryHeading: "LDL_C",
    key: "LDL_C",
    value: 0,
    min: 0,
    max: 200,
    lowerRange: 100,
    upperRange: 130,
  },
];

export let bloodSugarLevel = {
  FASTING: 1,
  "2_HOURS_POST_BREAKFAST": 2,
  PRE_LUNCH: 3,
  "2_HOURS_POST_LUNCH": 4,
  PRE_DINNER: 5,
  "2_HOURS_POST_DINNER": 6,
  BED_TIME: 7,
  "3_AM": 8,
  RANDOM: 9,
};

export let spo2Scale = {
  PULSE_OXIMETER: 1,
  ARTERIAL_BLOOD_GAS: 2,
};

export let temperatureScale = {
  CELSIUS: 1,
  FAHRENHEIT: 3,
};

export let pulseLocation = {
  WRIST: 1,
  NECK: 2,
};

export let genderList = {
  Male: 1,
  Female: 2,
  Other: 3,
};

export let bloodGroupType = {
  "A+": "A+",
  "A-": "A-",
  "B+": "B+",
  "B-": "B-",
  "O+": "O+",
  "O-": "O-",
  "AB+": "AB+",
  "AB-": "AB-",
};

export let occupationList = {
  Anesthesiologist: "Anesthesiologist",
  Cardiologist: "Cardiologist",
  Dentist: "Dentist",
  Dermatologist: "Dermatologist",
  Gastroenterologist: "Gastroenterologist",
  Neurologist: "Neurologist",
};

export const opdList = [
  { index: 0, label: "Patient name" },
  { index: 1, label: "Appointment date" },
  { index: 2, label: "Appointment Slot" },
  { index: 3, label: "Actions" },
];

export const ipdList = [
  { index: 0, label: "Patient name" },
  { index: 1, label: "Patient ID" },
  { index: 2, label: "Room type" },
  { index: 3, label: "Room Number" },
  { index: 4, label: "Actions" },
];

export const opdInputList = [
  {
    name: "John",
    appointmentDate: "12-02-2023",
    appointmentSlot: "5:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Elena",
    appointmentDate: "5-04-2023",
    appointmentSlot: "8:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Tom",
    appointmentDate: "24-05-2023",
    appointmentSlot: "1:00 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Kim",
    appointmentDate: "30-06-2023",
    appointmentSlot: "2:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Shweta",
    appointmentDate: "16-07-2023",
    appointmentSlot: "6:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Vaishali",
    appointmentDate: "24-08-2023",
    appointmentSlot: "3:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Vandana",
    appointmentDate: "19-09-2023",
    appointmentSlot: "9:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Shweta",
    appointmentDate: "21-09-2023",
    appointmentSlot: "5:00 pm",
    appointmentType: "5:30 pm",
  },
];

export const patientList = [
  {
    id: "22j23jkk232k5k32nj545",
    name: "Product 1",
    image: "https://example.com/images/product1.jpg",
    price: 10.99,
  },
  {
    id: "25nkj243bjb2j23",
    name: "Product 2",
    image: "https://example.com/images/product2.jpg",
    price: 19.99,
  },
  {
    id: "56ihj3kj4i64435jk5",
    name: "Product 3",
    image: "https://example.com/images/product3.jpg",
    price: 15.99,
  },
];

export const appointmentOpdListTab0 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  actions: "Actions",
  accept_decline : "Accept/Decline",
};


export const appointmentOpdListTab1_2 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  button: "Messages",
  status: "Status",
  medication: "Medication",
  actions: "Actions",
};

export const PatientBillingInvoiceList = {
  invoiceNumber: "Invoice number",
  createdAt: "Date",
  amount: "Amount",
  billingStatus: "Status",
  invoiceUrl: "Action"
};

export const patientAppointmentHeadingList = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
  patient_appoint_action: "Action"
}

export const patientAppointmentHeadingListTab3 = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
  book_again_appointment_action: "Action"
}

export const patientAppointmentHeadingListTab4 = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
}

export const appointmentOpdListTab3 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  actions: "Actions",
};

export const appointmentOpdListTab4 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  status: 'Status',
};

export const appointmentHeadingList = [
  { index: 0, label: "Appointed Doctor" },
  { index: 1, label: "Date & Time" },
  { index: 2, label: "Appointment type" },
  { index: 3, label: "Payment Type" },
  { index: 4, label: "Fees" },
  { index: 5, label: "Status" },
];

export const tabs = [
  { id: 0, name: "Pending" },
  { id: 1, name: "Upcoming" },
  { id: 2, name: "Completed" },
  { id: 3, name: "Cancelled" },
];

export const symptoms = [
  { name: "Hypertension" },
  { name: "Fever" },
  { name: "Fatigue" },
  { name: "Headache" },
  { name: "Cough" },
  { name: "Shortness of breath" },
  { name: "Sore throat" },
  { name: "Runny or stuffy nose" },
  { name: "Joint pain" },
  { name: "Nausea and vomiting" },
  { name: "Diarrhea" },
  { name: "Chest pain" },
  { name: "Abdominal pain" },
  { name: "Skin rash or redness" },
  { name: "Swelling in the extremities" },
  { name: "Dizziness or lightheadedness" },
  { name: "Changes in vision" },
  { name: "Difficulty speaking or swallowing" },
  { name: "Memory problems or confusion" },
  { name: "Unintended weight loss or gain" },
];

export const appointmentFor = [
  { label: "Self", value: 0 },
  { label: "Others", value: 1 },
];

export const scheduleTabs = [
  {
    id: 0,
    name: strings.onlineAppointment,
  },
  {
    id: 1,
    name: strings.inclinicAppointment,
  },
];

export let daySchedule = {
  "Show Day schedule": "d",
  "Show Weekly schedule": "w",
};

export const threeTabs = [
  { id: 0, name: "Request" },
  { id: 1, name: "Today" },
  { id: 2, name: "Upcoming" },
  { id: 3, name: "Completed" },
  { id: 4, name: "Cancelled" },
];

export const appointmentTabList = [
  {
    id: 0,
    name: strings.patientInformation,
  },
  {
    id: 1,
    name: strings.healthParameter,
  },
  {
    id: 2,
    name: strings.medicalHistory,
  },
  {
    id: 3,
    name: strings.reportsPrescription,
  },
  {
    id: 4,
    name: strings.previousAppointment,
  },
];

export const patientInfo = {
  name: "Name",
  email: "Email Address",
  contactNumber: "Contact Number",
  gender: "Gender",
  dob: "Date of birth",
  address: "Address",
  weight: "Weight",
  height: "Height",
};

export const previousAppointmentList = {
  appointment_type: "Appointment type",
  diagnosisAndObservation: "Diagnosis and observation",
  name: "Medication name",
  symptoms: "Symptoms",
  strength: "Strength",
  strengthType: "Strength Type",
  needDoctor: "Why do you need a doctor?",
  dosageQuantity: "Dosage quantity",
  documents: "Documents",
  otherInstructions: "Other instruction",
};

export const ePrescriptionHeadingList = {
  doctor_info : "Doctor's Name",
  updatedAt: "Date & Time",
  fileName: "File Name",
  doctor_appointement_action: 'Action',
};

export const reportHeadingList = {
  name: "File Name",
  updatedAt: "Date & Time",
  // file_url: "File Name",
  all_type: "All Type",
  doctor_appointement_report_action: 'Action',
};

export const daysOfWeek = [
  { level: 0, label: "M", nameOfDay: "Monday" },
  { level: 1, label: "T", nameOfDay: "Tuesday" },
  { level: 2, label: "W", nameOfDay: "Wednesday" },
  { level: 3, label: "T", nameOfDay: "Thursday" },
  { level: 4, label: "F", nameOfDay: "Friday" },
  { level: 5, label: "S", nameOfDay: "Saturday" },
  { level: 6, label: "S", nameOfDay: "Sunday" },
];

export const shiftData = [
  { level: 1, label: "First Shift"},
  { level: 2, label: "Second Shift" },
];

export const slotType = [
  { level: 0, label: "Online" },
  { level: 1, label: "In-Clinic" },
];

export const dosageIntake = [
  {id: 1, level: "once daily", label: "Once daily", },
  {id: 2, level: "twice daily", label: "Twice daily", },
  {id: 3, level: "when needed", label: "When needed", },
  {id: 4, level: "3 times daily", label: "3 times daily", },
  {id: 5, level: "4 times daily", label: "4 times daily", },
  {id: 6, level: "5 times daily", label: "5 times daily", },
  {id: 7, level: "every other day", label: "Every other day", },
  {id: 8, level: "every 2 hours", label: "Every 2 hours", },
  {id: 9, level: "every 3 hours", label: "Every 3 hours", },
  {id: 10, level: "every 4 hours", label: "Every 4 hours", },
  {id: 11, level: "every 6 hours", label: "Every 6 hours", },
  {id: 12, level: "every 8 hours", label: "Every 8 hours", },
  {id: 13, level: "every 12 hours", label: "Every 12 hours", },
  {id: 14, level: "every hour", label: "Every hour", },
  {id: 15, level: "every other hour", label: "Every other hour", },
  {id: 16, level: "once a week", label: "Once a week", },
  {id: 17, level: "twice a week", label: "Twice a week", },
  {id: 18, level: "thrice a week", label: "Thrice a week", },
  {id: 19, level: "4 days in a week", label: "4 days in a week", },
  {id: 20, level: "5 days in a week", label: "5 days in a week", },
  {id: 21, level: "once every 2 weeks", label: "Once every 2 weeks", },
  {id: 22, level: "once a month", label: "Once a month", },
  {id: 23, level: "twice a month", label: "Twice a month", },
  {id: 24, level: "thrice a month", label: "Thrice a month", },
  {id: 25, level: "every other month", label: "Every other month", }
];

export const getBackgroundColor = status => {
  if ([1, 4].includes(status)) return "#2AAB6C";
  if ([2, 3, 5].includes(status)) return "#F36363";
  return "#EAAD11";
};

export const validExtensions = ['jpg', 'jpeg', 'pdf', 'png'];
export const getStatusText = status => {
  const statusMap = {
    0: "Pending",
    1: "Approved",
    2: "Reject",
    3: "Cancelled by patient",
    4: "Completed",
    5: "Cancelled by doctor"
  };
  return statusMap[status] || "Rejeced by doctor";
};

export const settingTabs = [
  { id: 0, name: "Personal Details" },
  { id: 1, name: "Reset Password" },
]