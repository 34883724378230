import colors from "../../constant/colors";
import { makeStyles } from '@mui/styles';

const commonMuiStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8, // Set the border radius to 20px
      backgroundColor: colors.white3
      //   backg: `${colors.textFieldColor}`,

    },
  },
  iconStyle: {
    color: colors.grey1,
    width: 18,
    height: 18,
  },
  customTab: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    background:
      "var(--primary, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%))",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  customTabIndicator: {
    background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
  },
  customAutocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      background: colors.white3,
      borderRadius: "8px",
    },
    "& .MuiAutocomplete-input": { padding: 0, color: "black" },
  },
}));

export { commonMuiStyles };

