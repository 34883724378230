import React, { useContext, useState } from 'react';

import HomeSlider from './HomeSlider';
import CardWithImage from './CardWithImage';
import Card from '../../components/Card';
import Testimonial from './Testimonial';
import Virtual from './virtual';
import {Helmet} from "react-helmet";
import {
  StyledLangingWrapper,
  StyledSubTitle,
  StyledCardWrapper,
  StyledCardIconWrapper,
  StyledCardIcon,
  StyledCardTitle,
  StyledCardSubTitle,
  StyledCardButton,
  StyledCardButtonIcon,
  StyledHomeSlider
} from './styles';
import { Link, useHistory } from 'react-router-dom';

import CommonSignUpModal from '../../components/Header/CommonSignUpModal';
import { Context } from '../../store';
import LoginModal1 from '../../components/Header/LoginModal1';

function LandingPage(props) {
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  const handleLoginOpen = () => {
		document.body.style.overflowY = 'auto'
		setLoginToggle(loginToggle => !loginToggle);
	}

  const servers = [
    // KetOnTrack
    {id: 3, title: 'NLP', subtitle: '', route: '/nlp', icon: '/assets/img/icons/NLP.png'},
    {id: 2, title: 'Yoga Nutrition', subtitle: '', route: '/yoga-nutrition', icon: '/assets/img/icons/Yoga.png'},
   
    {id: 1, title: 'Bio Hacking', subtitle: '', route: '/bio-hacking', icon: '/assets/img/icons/Bio-Hicking.png'},
  ];

  const tools = [
    {id: 1, description: 'APPOINTMENTS', title: 'Manage Your Appointments on the Go', icon: '/assets/img/icons/icon1.png', image: '/assets/img/land1.png', text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.', route: '/manage-your-appointments'},
    {id: 2, description: 'VIDEO CONSULTATION', title: 'Video / Text Consultations in your Virtual Practice', icon: '/assets/img/icons/icon2.png', image: '/assets/img/land2.png', text: 'Using the Virtual Practice tool, you can meet more patients every day. An excellent, quick, and efficient tool helps follow-up with your patients, wherever they are located. Elderly patients and others with disabilities who are located in a distant place can easily connect with you. With greater collaboration, you can follow up with them for proper health care and monitor their health in a better way. ', route: '/video-consultations'},
    {id: 3, description: 'PATIENT QUESTIONS', title: 'Communication Made Simple', icon: '/assets/img/icons/icon3.png', image: '/assets/img/land3.png', text: 'You can communicate with your patients with just a simple click. Tell them about what’s new and keep them updated with the events relevant to them.', route: '/communication-made-simple'},
    {id: 4, description: 'MOBILE APPS', title: 'Provide Better Health Outcomes – Using Patient Health Record Management (PRM)', icon: '/assets/img/icons/icon4.png', image: '/assets/img/land4.png', text: 'Using the Patient Health Record Management (PRM), you can know more about the patient’s health reports and comprehensive medical history right at your fingertips.', route: '/provide-better-health'},
    {id: 6, description: 'REMOTE MONITORING', title: 'Tele Monitoring In Your Virtual Clinical Practice/ Clinical Institute', icon: '/assets/img/icons/icon5.png', image: '/assets/img/land5.png', text: 'Ensure that your patients get the best health care. Custom care plans that monitor the patients can reduce the risk of health complications between visits.', route: '/tele-monitoring-virtual-clinical'},
    {id: 5, description: 'BILLING & REPORTS', title: 'Billing & Reports', icon: '/assets/img/icons/icon6.png', image: '/assets/img/land6.png', text: 'Keep track of your growing practice by collecting payments online. Manage online billing and revenue reports that help in tracking your practice revenue. ', route: '/billing-and-reports'}
  ];

  const [currentTool, setCurrentTool] = React.useState({
    id: 1,
    text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.',
    image: '/assets/img/land1.png',
    route: '/manage-your-appointments'
  })

  const [toolId, setToolId] = React.useState(1);

  const changePage = (route) => {
    props.history.push(route);
  }

  const onSetCurrentTool = (item) => {
    setToolId(item.id)

    setCurrentTool({
      id: item.id,
      text: item.text,
      image: item.image,
      route: item.route
    })
  }

  const changePageTele = (route) => {
    props.history.push(route);
  }

  const blogs = [
    {
      id: 1, 
      name: 'COVID-19 Vaccination: EVERYTHING YOU NEED TO KNOW',
      description: 'WHO has authorized the administration of the coronavirus vaccine to check the spread of COVID-19. There are various types of vacci...',
      route: '/blogs/Covid-19 vacination',
      blogImage: "https://api.emed4u.com/uploads/2021-06-09T10:36:53.606ZCoronavirus-Vaccine-covid-19-2.png"
    },
    {
      id: 2, 
      name: 'What is Plasma and hw it works?',
      description: 'We live in harrowing times where every new day brings another hike in the COVID-19 graph. The virus has encompassed our life and...',
      route: '/blogs/simplifying-plasma-donation-and-why-you-should-do-it',
      blogImage: "https://api.emed4u.com/uploads/2021-05-27T19:47:41.494Zplasma.png"
    }
  ]

  const getRoute = (type) => {
		if (type === 'customer') {
			return '/patient/dashboard';
		} else if (type === 'doctor') {
			return '/doctor/schedule';
		} else if (type === 'organization') {
			return 'organization/dashboard';
		} else if (type === 'subuser') {
			return 'subuser/dashboard';
		}
	}

  const handleClickButton  = () => {
    if (state && state?.userType) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      history.push(getRoute(state?.userType));
    } else {
      setSignUpModal(!isSignUpModal)
    }
  }

  return (
    <StyledLangingWrapper className="section full-slide-home">
      <Helmet title = {'Best Hospital Management Solution, Patiant & lab management solution'}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            name: `description`,
            content:'Online Healthcare Management Solution for your Hospital, Clinic, Laboratory, Patient Appointments System, Billing & Reporting.',
          },
          {
            name: `keywords`,
            content:'Clinic Management System, Practice Management System Software, Hospital Management system, Online doctor appointment',
          },
          {
            property: "url",
            content: 'https://emed4u.com/hospital-management-system'
          },
        ]}
      />
      <HomeSlider /> 

      <div style={{textAlign: 'center', margin: '20px 0'}}>
          <h1 className='emed4u-heading'>Smart Healthcare Mangement System</h1>
          <button style={{border: 'none'}} className='emed4u-link'>
            <Link style={{whiteSpace: 'pre'}} to={'/hms'}>
              {`Try out Emed4u HMS >`}
            </Link>
            </button>
      </div>

      <StyledHomeSlider>
        <div style={{justifyContent: 'end'}} className='col d-flex bd-home-slider bg-img-work-001 mobile-screen-slide-image'>
          <div className="slide-image col-12 col-lg-8">
            <h2 className="mb-3" style={{ textAlign: 'left' }}>
              Integrated system entwined with Rich Data, Patient Management, Intuitive Analytics, Lead Tracking, and Patient Monitoring
            </h2>
            <div className="d-inline-block img-dsk-sh-book" style={{ textAlign: 'left' }}>
              <Link onClick={() => handleClickButton()} to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
            </div>
          </div>
          <div className={`col-12 col-lg-6 d-flex img-dsk-sh bg-home-mobile-001 flex-none`}>
            <div className="d-inline-block img-dsk-sh-book-01">
              <Link onClick={() => handleClickButton()} to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
            </div>
          </div>
        </div>

      <div style={{textAlign: 'center', margin: '20px 0'}}>
          <h1 className='emed4u-heading'>Health Wearables</h1>
          {/* <a href className='emed4u-link'>{`Shop Now >`}</a> */}
      </div>

      <div className='col-12 d-flex bd-home-slider bg-img-watch-001'>
        <div className="slide-image col-12 col-lg-8">
          <h2 className="mb-3" style={{ textAlign: 'left' }}>
            Discover an ecosystem of award-winning smart devices that can shed new light on your health and sustainably improve it.
          </h2>
          <div className="d-inline-block img-dsk-sh-book" style={{ textAlign: 'left' }}>
            <Link onClick={() => handleClickButton()} to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
          </div>
        </div>
        <div className={`col-12 col-lg-6 d-flex img-dsk-sh bg-home-mobile-001 flex-none`}>
          <div className="d-inline-block img-dsk-sh-book-01">
            <Link onClick={() => handleClickButton()} to className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
          </div>
        </div>
      </div>

      <div style={{textAlign: 'center', margin: '20px 0'}}>
        <h1 className='emed4u-heading'>Our Mobile App</h1>
      </div>
      </StyledHomeSlider>

      {/*<section className="section home-tile-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 m-auto">
              <div className="section-header text-center">
                <h2>What are you looking for?</h2>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <CardWithImage
                    img="/assets/img/loginPatient.jpg" 
                    title="For patient"
                    button="Login now"
                    route={'/login'}
                  />
                </div>
              <div className="col-lg-4 mb-3">
                <CardWithImage
                  img="/assets/img/loginDoctor.jpg" 
                  title="For a doctor / practitioner"
                  button="Login now"
                  route={'/doctor/login'}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <CardWithImage
                  img="/assets/img/lab-image.jpg" 
                  title="For an Organisation / Clinic"
                  button="Login now"
                  route={'/organization/login'}
                  />
              </div>
            </div>
          </div>
        </div>
		  </div>
	  </section>  
    
    <section className="section section-specialities">
      <div className="container-fluid">
        <div className="section-header text-center">
          <StyledSubTitle>Our Services</StyledSubTitle>
          <h2>Our Healthcare Services</h2>
        </div> 
        <div className="row">
          <div className="col-md-9 m-auto">
            <div className="row">
              {
                servers.map(server => {
                  return <div className="col-lg-4 mb-3" key={server.id}>
                          <Card className="text-center">
                            <StyledCardWrapper>
                              <StyledCardIconWrapper>
                                <StyledCardIcon src={server.icon} />
                              </StyledCardIconWrapper>
                              <StyledCardTitle>
                                {server.title}
                              </StyledCardTitle>
                              <StyledCardSubTitle>
                                {server.subtitle}
                              </StyledCardSubTitle> <br />
                              <StyledCardButton onClick={() => changePage(server.route)}>
                                Read more     
                                <i className="fa fa-chevron-right" />
                              </StyledCardButton>
                            </StyledCardWrapper>
                          </Card>
                        </div>
                })
              }
              
            </div>
          </div>
        </div>
      </div>
            </section>*/}

    {/* <section className="section section-doctor">
      <div className="container-fluid">
        <div className="row">
            <div className="section-header col-lg-12 text-center">
              <h2>Virtual Practice/Teleclinic/ Virtual Hospital tools</h2>
            </div>
          <div className="col-lg-8">
          
            <div className="row">
              {
                tools.map(tool => {
                  return <div className="col-lg-4 mb-3" key={tool.id}>
                          <Card className="text-center">
                            <StyledCardWrapper className={toolId === tool.id ? 'active-card-section' : ''} onClick={() => onSetCurrentTool(tool)}>
                              <StyledCardIconWrapper>
                                <StyledCardIcon src={tool.icon} />
                              </StyledCardIconWrapper>
                              <StyledCardTitle>
                                {tool.title}
                              </StyledCardTitle>
                              
                            </StyledCardWrapper>
                          </Card>
                        </div>
                })
              }
            </div>
          </div>
          <div className="col-lg-4 features-img">
            <div className="about-content">
              <p>{currentTool.text}</p>
              <StyledCardButton onClick={() => changePageTele(currentTool.route)}> 
                Read more     
                <i className="fa fa-chevron-right" />
              </StyledCardButton>
            </div>
            <img src={currentTool.image} className="img-fluid" alt="Feature" />
          </div>
        </div>
      </div>
    </section> */}

    {/*<Virtual props={props} />

    <section className="section section-specialities">
      <div className="container-fluid">
        <div className="section-header text-center">
          <StyledSubTitle>Our Blogs</StyledSubTitle>
          <h2>Latest Blogs</h2>
        </div> 
        <div className="row">
          <div className="col-md-9 m-auto">
            <div className="row">
              {
                blogs.map(blog => {
                  return <div className="col-lg-5 mb-3" key={blog.id}>
                          <Card>
                            <div className="blogEmed4u-img">
                              <img width={'100%'} src={blog.blogImage} />
                            </div>
                            <div className="blogEmed4u-content">
                              <h5>{blog.name}</h5>
                              <p>{blog.description}</p>

                              <button onClick={() => changePage(blog.route)}>Read More <i className="fa fa-chevron-right"></i></button>
                            </div>
                          </Card>
                        </div>
                })
              }
              
            </div>
          </div>
        </div>
      </div>
            </section>*/}

    <section className="section section-specialities bgapp-emed4u">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="bgapp-emed4u-image">
              <img src="/assets/img/land2.png" />
            </div>
          </div>
          <div className="col-6">
            <div className="section-header">
              <h2 className="mobile-screen-header">Download Emed4u App Now!</h2>
            </div>
            <div className="row ">
              {/* <div className="col-md-6">
                <img src="/assets/img/appp.jpeg" />
              </div> */}

              <div className="col-md-12">
                <div className="our-app-show ">
                 <a href="https://play.google.com/store/apps/details?id=com.emed4ulastestapp" target="_blank" class="link-block-2 w-inline-block"></a>

                  <a href="https://apps.apple.com/in/app/emed4u/id1573147985" target="_blank" class="link-block w-inline-block"></a>
                 
                 
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>

    {/* <section className="section section-specialities">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header text-center">
              <h2>Download Emed4u App Now!</h2>
            </div>
            <div className="row text-center">
              <div className="col-md-12">
                <div className="our-app-show text-center" style={{    'justify-content': 'center'}}>
                 <a href="https://play.google.com/store/apps/details?id=com.emed4ulastestapp" target="_blank" class="link-block-2 w-inline-block"></a>

                  <a href="https://apps.apple.com/in/app/emed4u/id1573147985" target="_blank" class="link-block w-inline-block"></a>
                 
                 
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section> */}

    {/* <section className="section section-specialities pt-0" style={{paddingBottom: '80px'}}>
      <div className="container-fluid">
        <div className="text-center d-flex lan-cer-emd" style={{justifyContent: 'center'}}>
          <div className="cert-img">
            <img src="/assets/img/iso-9001-2015-certification.jpg" />
          </div>

          <div className="cert-img-1">
            <img src="/assets/img/iso-27000-certification.jpg" />
          </div>
         
        </div> 
      </div>
    </section> */}
    	<div className="modal custom-modal fade none-border show handleLoginOpen" style={{
					display: loginToggle ? 'block' : 'none', backdropFilter: 'blur(1px)',
					background: 'rgb(104 71 99 / 24%)'
				}}>
					<div className="overlay-background-disable" />
					<LoginModal1
						isSignUpModal={isSignUpModal}
						// handleOpen={handleLoginOpen}
						setLoginToggle={setLoginToggle}
						loginToggle={loginToggle}
						toggleLogin={() => {
							handleLoginOpen()
							setSignUpModal(true)
						}} 
					/>
				</div>
      <CommonSignUpModal 
				isSignUpModal={isSignUpModal} 
				setSignUpModal={setSignUpModal} 
				toggleSignUp={()=>{
					setSignUpModal(false);
					handleLoginOpen();
				}}
			/>
  </StyledLangingWrapper>
  )
}

export default LandingPage
