import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, FieldArray } from "formik";
import React, { useRef, useState } from "react";
import { useStyles } from "../../../../assets/styles/BloodSugarStyles";
import { StyledCrossIcon, StyledFormik, StyledSecondaryButton, StyledWithBorderButton } from "../../../../assets/styles/CommonStyles";
import * as mainService from "../../../../services/mainService.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../../../constant/strings";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import { medicationStyles } from "../../../../assets/styles/MedicationStyles";
import { STRENGTH_TYPE_ENUM } from "../../../../constants";
import { capitalizeFirstLetter } from "../../../../constant/staticHelper";
import SelectionDropdown from "../../../control/SelectionDropdown";
import { dosageIntake } from "../../../../constant/staticLists";
import { sendMedicationSchema } from "../../../../utils/forms/schema.sendMedicationSchema";
import { getDoctorAppointmentAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions";

const SendMedication = ({ setOpenModal, item, setTabValue, tabValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const medicationClasses = medicationStyles();
  const formikRef = useRef();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));
  const strengthList = STRENGTH_TYPE_ENUM.map((item) => ({
    label: capitalizeFirstLetter(item.replace(/_/g, ' ').toLowerCase()),
    level: item
  }));

  let initialValue = {
    appointmentId: '',
    diagnosisAndObservation: "",
    otherInstructions: "",
    settlePayment: "",
    formOfMedication: [
      {
        name: "",
        medicineStrength: "",
        strengthType: "",
        dosageQuantity: ""
      }
    ]
  }


  const handleValues = async (values, { resetForm }) => {
    values.appointmentId = item._id;
    const data = {
      values,
      token: userData.token
    }
    const res = await mainService.getSendMedicationApi(data);
    if (res.response_type === "fail") {
      setButtonLoading(false);
      resetForm();
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, x: { width: 'auto', borderRadius: 8 } });
    } else {
      setButtonLoading(false);
      setOpenModal(false);
      resetForm();
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
      
      dispatch(getDoctorAppointmentAction({ token: userData.token, status: 4 }));
    }
    setTabValue(3)
  }

  return (
    <div className={medicationClasses.mainCont}>
      <Grid className={classes.secondayCont}>
        <h2 className={classes.mainCont}>{strings.sendMedication}</h2>
        {/* <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          ref={formikRef}
          onClick={() =>{ setOpenModal(false); console.log('initialValue', initialValue) }}
        /> */}
      </Grid>
      <div>
        <Formik
          initialValues={initialValue}
          validationSchema={sendMedicationSchema}
          onSubmit={handleValues}
        >
          {({
            handleChange, errors, handleSubmit, setFieldValue, resetForm, values
          }) => {
            const handleDropdownValue = (fieldName, data, index) => {
              if (data) {
                const ids = data.level;
                if (fieldName === `formOfMedication[${index}].strengthType`) {
                  setFieldValue(`formOfMedication[${index}].strengthType`, ids);

                }
                else if (fieldName === `formOfMedication[${index}].dosageQuantity`){
                  setFieldValue(`formOfMedication[${index}].dosageQuantity`, ids);
                }
              }
            }

            return (
              <StyledFormik>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={medicationClasses.textStyle}>{strings.diagnosisAndObeservation}</div>
                    <FieldWrapper
                      name="diagnosisAndObservation"
                      label={strings.enterDiagnosisObservation}
                      type="text"
                    />
                  </Grid>
                  <FieldArray name="formOfMedication">
                    {fieldArrayHelpers => (
                      <>
                        {values.formOfMedication.map((_, index) => (
                          <>
                            <Grid item xs={12} className={medicationClasses.headerCont}>
                              <div className={`${medicationClasses.textStyle} ${medicationClasses.margin}`}>{strings.formOfMedication}</div>
                              {values.formOfMedication.length > 1 && (
                                <div
                                  type="button"
                                  className={medicationClasses.deleteTextStyle}
                                  onClick={() => fieldArrayHelpers.remove(index)}
                                >
                                  Delete
                                </div>)}
                            </Grid>
                            <Grid item xs={12} key={index}>
                              <FieldWrapper
                                name={`formOfMedication[${index}].name`}
                                type="text"
                                label={strings.enterMedicationName}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FieldWrapper
                                name={`formOfMedication[${index}].medicineStrength`}
                                type="text"
                                label={strings.enterMedicineStrength}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <SelectionDropdown
                                height={"36px"}
                                name={`formOfMedication[${index}].strengthType`}
                                label={`${strings.enterStrengthType}`}
                                list={strengthList}
                                className={medicationClasses.customAutocomplete}
                                handleDropdownValue={(data) => handleDropdownValue(`formOfMedication[${index}].strengthType`, data, index)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SelectionDropdown
                                height={"36px"}
                                name={`formOfMedication[${index}].dosageQuantity`}
                                label={`${strings.selectDosageIntake}`}
                                list={dosageIntake}
                                className={medicationClasses.customAutocomplete}
                                handleDropdownValue={(data) => handleDropdownValue(`formOfMedication[${index}].dosageQuantity`, data, index)}
                              />
                            </Grid>
                          </>
                        ))}
                        <Grid item xs={12}>
                          <div
                            className={medicationClasses.textStyle1}
                            onClick={() => fieldArrayHelpers.push({
                              name: "",
                              medicineStrength: "",
                              strengthType: "",
                              dosageQuantity: ""
                            })}
                          >
                            <img src={"/assets/img/plus.png"} alt="plus" style={{ height: "18px", width: "18px" }} />
                            Add More Medication
                          </div>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                  <Grid item xs={12}>
                    <div className={`${medicationClasses.textStyle} ${medicationClasses.margin}`}>{strings.otherInstruction}</div>
                    <FieldWrapper
                      name="otherInstructions"
                      type="text"
                      label={strings.enterNoteToPatient}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={`${medicationClasses.textStyle} ${medicationClasses.margin}`}>{strings.settlePayment}</div>
                    <FieldWrapper
                      name="settlePayment"
                      type="text"
                      label={strings.enterPendingPayment}
                    />
                  </Grid>
                  <Grid container spacing={2}  mt={2} ml={2} mr={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <StyledSecondaryButton
                      name="submit"
                      style={{ width:"100%"}}
                      disabled={Object.keys(errors).length > 0 || buttonLoading}
                      onClick={handleSubmit}
                      variant="contained">
                      {buttonLoading ? (
                        <CircularProgress
                          size={20}
                          thickness={5}
                        />
                      ) : `${strings.sendFinalPayment}`}
                    </StyledSecondaryButton>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} >
                    <StyledWithBorderButton
                    // disabled={Object.keys(errors).length > 0 || buttonLoading}
                      onClick={() => {
                        setOpenModal(false)
                        resetForm();
                      }}
                      variant="contained">
                      {buttonLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                          thickness={5}
                        />
                      ) : `${strings.notNow}`}
                    </StyledWithBorderButton>
                  </Grid>
                  </Grid>
                  
                </Grid>
              </StyledFormik>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default React.memo(SendMedication);