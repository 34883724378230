import React from 'react';
import { Link } from 'react-router-dom';

import {
	StyledContainer,
	StyledHeading,
	StyledPatient,
	StyledPatientSearch,
	StyledAddPatient
} from './styles';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getPatients
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card'
import TableComponent from '../../../../components/Table';
import InputField from '../../../../components/InputField';
import Pagination from '../../../../components/Pagination';

import moment from 'moment';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'phoneNumber' }
]

const tableHeading = [
	{id: 1, title: 'Patient'},
	// {id: 2, title: 'Latest appointments'},
	{id: 4, title: 'Joined Date'},
	{id: 5, title: 'Actions'}
];

function PatientListing(props) {
	const [state, dispatch] = React.useContext(Context);

	const [patients, setPatients] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('name');

	const fetchPatient = () => {
		loader(true);
		getPatients().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setPatients(res?.data);
		
		}).catch(err => {
			loader(false);
			setPatients([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		fetchPatient();
	}, [])

	const searchPatient = () => {
		loader(true);
		const payload = {
			[selectValue]: search
		}
		getPatients(search?.length > 0 ? payload : '').then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setPatients(res?.data);
		}).catch(err => {
			setPatients([]);
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [patientPaginate, setPatientPaginate] = React.useState([]);
	const pageChangePatient  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPatientPaginate(data);
	}

	React.useEffect(() => {
		fetchPatient();
	}, [selectValue])

	React.useEffect(() => {
        if(search === '') {
            fetchPatient();
        }
    }, [search])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-2">
								<StyledHeading></StyledHeading>
								<StyledPatient>
									<div className="doctor-panel-filters row col-md-12 p-4">
										<Search
											selectValue={selectValue} 
											setSelectValue={setSelectValue} 
											search={search} 
											setSearch={setSearch} 
											searchPatient={searchPatient} 
											name={"Patient's"}
											options={options}
										/>
										<div className="col-md-2 mt-5 mb-0 no-padding form-group doctor-active-btn-div">
											<Link to={'/organization/patient-add'} style={{width: '100%'}}>
												<StyledAddPatient>
													Add a patient
												</StyledAddPatient>
											</Link>
										</div>
									</div>
								</StyledPatient>
								<TableComponent tableHeading={tableHeading}>
									{
										patients?.length ? patientPaginate && patientPaginate.map(item => {
											return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.profileImage ? item.profileImage : "/assets/img/loading-img.jpg"} alt="User Image" />
																	</a>
																	<a>{item.name} </a>
																</h2>
															</td>
															{/* <td>14 Dec 2020</td> */}
															<td>{moment(item.createdAt).format('DD/MM/YYYY, hh:mm a')} </td>
															<td>
																<div className="table-action">
																	<Link to={`/organization/patient-edit/${item._id}`} className="btn btn-sm bg-info-light-blue">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										}):  (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center", margin: '0', padding: '20px 0' }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										)
									}
									
								</TableComponent>

								{patients.length > 0 && <Pagination items={patients} onChangePage={pageChangePatient} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientListing
