import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";

const useStyles = makeStyles(() => ({
    titleCont:{
        background: colors.white,
        width: '100%',
        maxHeight: 56,
        boxShadow: "none",
        border: "none",
        outline: "none"
    },
    iconStyle:{
        width: "167px",
        height: 40
    },
    
}))

export { useStyles };