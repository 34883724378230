import { Grid } from "@mui/material";
import React from "react";

//import Appointments from "../../components/ui/Doctor/Appointments";

const OrganisationBillingInvoice = () => {

  return (
    <Grid container spacing={0}>
    <div>OrganisationBillingInvoice</div>
    </Grid>
  )
}

export default OrganisationBillingInvoice;