import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";
import TabComponent from "../../../Tab";
import Tables from "../../../controls/Tables";
import EPriscriptions from "../../EPriscriptions";
import strings from "../../../../constant/strings";
import HeadingComponent from "../../HeadingComponent";
import PatientInformation from "./PatientInformation";
import PreviousAppointments from "./PreviousAppointments";
import PatientDashboard from "../../Patient/PatientDashboard";
import PersonalMedicalDetails from "../../PersonalMedicalDetails";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import SearchFields from "../../../../pages/PatientPanel/PatientAppointments/SearchFields";
import MedicalReports from "../../MedicalReports";
import { confirmAppointmentAction, getDoctorAppointmentAction, getPatientDetailsForDoctorAction, getPatientPreviousAppointmentForDoctorAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions";
import { getMedicalHistoryAction, getPatientEprescriptionAction, getPatientMedicalReportAction } from "../../../../redux/user/userActions";
import SendMedication from "./SendMedication";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import { LoadingContainer, NoDetailsContainer, modalStyle } from "../../../../assets/styles/CommonStyles";
import { appointmentOpdListTab0, appointmentOpdListTab1_2, appointmentOpdListTab3, appointmentOpdListTab4, threeTabs } from "../../../../constant/staticLists";
import * as mainService from "../../../../../src/services/mainService";
import { toast } from "react-toastify";

const Appointments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const [tabValue, setTabValue] = useState(0);
  const [selectedListItem, setSelectedListItem] = useState("");
  const [appointmentType, setAppointmentType] = useState(0);
  const [appointmentList, setAppointmentList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { userData, doctorAppointmentList, loading, medicalHistory } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorAppointmentList: state.doctorAppointment.doctorAppointmentList,
    loading: state.doctorAppointment.loading,
    medicalHistory: state.user.medicalHistory
  }));

  useEffect(() => {
    const getDoctorAppointments = (status) => {
      dispatch(getDoctorAppointmentAction({ token: userData.token, status: status }));
    };
    switch (tabValue) {
      case 0:
        getDoctorAppointments(0);
        break;
      case 1:
      case 2:
        getDoctorAppointments(1);
        break;
      case 3:
        getDoctorAppointments(4);
        break;
      case 4:
        getDoctorAppointments(2);
        break;
      default:
        break;
    }
  }, [tabValue])

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValue = (value) => {
    const matchingEntries = appointmentList?.filter((entry) => entry.patientInfo.name.toLowerCase().includes(value));
    setAppointmentList(matchingEntries);
    if (value === "") {
      setAppointmentList(doctorAppointmentList);
    }
  };

  const handleAppointmentTab = (event, newValue) => {
    setAppointmentType(newValue);
    const todayDate = selectedListItem ? moment(selectedListItem.start_time).utc().format("YYYY-MM-DD") : "";
    const value = {
      token: userData.token,
      appointmentId: selectedListItem?._id,
      date: todayDate,
    };
    const value1 = {
      token: userData.token, id: selectedListItem._id
    }
    const getValue = () => {
      dispatch(getPatientEprescriptionAction(value))
      dispatch(getPatientMedicalReportAction(value))
    }
    if (newValue !== 1) {
      newValue === 0 ? dispatch(getPatientDetailsForDoctorAction(value1)) :
        newValue === 2 ? dispatch(getMedicalHistoryAction(value)) : 
          newValue === 3 ? getValue() :
            dispatch(getPatientPreviousAppointmentForDoctorAction(value))
    }
  };

  const selectedItem = (item) => {
    if (item._id != selectedListItem._id) {
      setAppointmentType(0);
    }
    if (appointmentType === 0) {
      dispatch(
        getPatientDetailsForDoctorAction({
          token: userData.token,
          id: item._id,
        })
      );
    }
    setSelectedListItem(item);
  };

  useEffect(() => {
    if (doctorAppointmentList === undefined || doctorAppointmentList.length === 0) {
      setAppointmentList([]);
      return;
    }
    const formattedData = doctorAppointmentList.map((item) => ({
      ...item,
      start_time: moment(item.start_time).format("YYYY-MM-DDTHH:mm"),
      end_time: moment(item.end_time).add(1, "seconds").format("YYYY-MM-DDTHH:mm"),
    }));
    let filteredData = [];
    if (tabValue === 1) {
      const today = moment().startOf("day");
      filteredData = formattedData.filter((item) => moment(item.start_time).isSame(today, "day"));
    } else if (tabValue === 2) {
      const todayDate = moment().format("YYYY-MM-DD");
      filteredData = formattedData.filter((item) => moment(item.start_time).isAfter(todayDate, "day"));
    } else {
      filteredData = formattedData;
    }

    setAppointmentList(filteredData);
  }, [doctorAppointmentList, tabValue]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const onmedicationClick = (openModal, item) => {
    setOpenModal(!openModal);
    setSelectedListItem(item);
  };

  const appointmentDecision = async (item, bool) => {
    const data = { appointmentId: item._id, appointmentAccepted: bool, deleteSlot: false };
    // dispatch(confirmAppointmentAction({ token: userData.token, data }));
    const res = await mainService.confirmAppointmentApi({ token: userData.token, data });
    if (res.response_type === "fail") {
      //toast.error(res?.response?.message, { position: "bottom-center", closeButton: false })
    } else {
      //toast.success(res?.response?.message, { position: "bottom-center", closeButton: false })
      dispatch(getDoctorAppointmentAction({ token: userData.token, status: 0 }));
    }
    //dispatch(getDoctorAppointmentAction({ token: userData.token, status: 0 }));
  };

  const renderAppointmentType = () => {
    switch (appointmentType) {
      case 0:
        return <PatientInformation item={selectedListItem} />;
      case 1:
        return <PatientDashboard navigate="doctor" item={selectedListItem} />;
      case 2:
        return <PersonalMedicalDetails navigate="doctor" item={selectedListItem} />;
      case 3:
        return (
          <>
            <MedicalReports navigate="doctor" />
            <EPriscriptions navigate="doctor" />
          </>
        );
      default:
        return <PreviousAppointments />;
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.mainCont}>
        <HeadingComponent
          text={{
            primary: strings.appointmentsOpd,
            secondary: strings.appointmentOpdHeading,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <TabComponent
              tabs={threeTabs}
              tabValue={tabValue}
              setTabValue={setTabValue}
              handleTab={handleTab}
              customTab={styleClasses.customTab}
              customTabIndicator={styleClasses.customTabIndicator}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchFields
              pr={"16px"}
              handleValue={handleValue}
              placeholder={strings.searchByPatientName}
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? <LoadingContainer>{strings.loading}</LoadingContainer> : !!appointmentList && appointmentList.length > 0 ? (
              <Tables
                data={appointmentList}
                fieldsOrder={
                  tabValue === 0
                    ? appointmentOpdListTab0
                    : tabValue === 1 || tabValue === 2
                      ? appointmentOpdListTab1_2
                      : tabValue === 3
                        ? appointmentOpdListTab3
                        : appointmentOpdListTab4
                }
                tabValue={appointmentType}
                setTabValue={setAppointmentType}
                handleTab={handleAppointmentTab}
                selectedItem={selectedItem}
                openModal={openModal}
                onmedicationClick={onmedicationClick}
                onTab={tabValue}
                appointmentDecision={appointmentDecision}
              >
                <>{renderAppointmentType()}</>
              </Tables>
            ) : (
              <NoDetailsContainer>No appointments available</NoDetailsContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={openModal}
        //onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle}>
          <SendMedication item={selectedListItem} 
          setOpenModal={setOpenModal}
          tabValue={tabValue}
          setTabValue={setTabValue}
           />
        </Box>
      </Modal>
    </Grid>
  );
};

export default Appointments;
