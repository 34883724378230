import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid} from "@mui/material";
import { DeptHeading } from '../../../../../../assets/styles/CommonStyles';
import { useStyles } from './MUIstyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const DeptCheckBox=({data})=>{
    
    const classes = useStyles();
    const [checkedValue, setCheckedValue] = useState([]);
    
    const handleChange=(value)=>{
        if((checkedValue).includes(value)){
            setCheckedValue(checkedValue.filter((item)=> item!==value))
        }
        else {
            setCheckedValue([...checkedValue, value])
        }
    }
    return (
        <div>
        <Grid container spacing={8} >
        {data.map((data, index)=> <Grid item xs={6}>
        <Grid key={index} container className={`${checkedValue.includes( data.title ) ? classes.checkedCheckbox : classes.checkBoxContainer}`} justify="center" >
        <FormControlLabel
        control={<Checkbox checked={checkedValue.includes(data.title)} 
        onChange={()=>handleChange(data.title)} hidden />
    }
    label= {
        <div >   
        
        <div className={classes.imageContainer}>
        { checkedValue.includes( data.title ) ? <img src="assets/img/done.png" alt="done" /> :
        <CheckCircleOutlineIcon color= 'disabled' fontSize='small' /> 
    }
    </div> 
    
    <DeptHeading> {data.title} </DeptHeading>
    <DeptHeading style={{fontWeight: "400", fontSize: "12px"}}>{data.fullName}</DeptHeading>
    </div>
} 
checked={checkedValue.includes( data.title )} />
</Grid>


</Grid>)}


</Grid>
</div>

)
}

export default DeptCheckBox;
