import { makeStyles } from "@mui/styles";

import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const profileStyles = makeStyles(() => ({
  mainSecCont: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: '1px solid #dddddd',
    padding: "0px",

  },
  textStyle: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: fontWeights.fontWeight500,
    textTransform: "capitalize",
    lineHeight: '18px',
  },
  imageCont: {
    height: 124,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageStyle: {
    height: 104,
    width: 104,
    borderRadius: 104 / 2,
    border: '1px solid #dddddd',
  },
  tabFontSize: {
    fontSize: 14,
    textTransform: "capitalize"
  },
  formikCont:{
    marginTop: 32
  }
}));

export { profileStyles };
