import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
//import Appointments from "../../components/ui/Doctor/Appointments";

const OrganisationSubAdminList = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        {/* <Appointments /> */}
        <div>OrganisationSubAdminList</div>
      </AppLayout>
    </Grid>
  )
}

export default OrganisationSubAdminList;