import { object, string, number } from "yup";
import * as Yup from 'yup';

export const doctorProfileSchema = object({

  // name:
  // profile_image: 
  // gender: 
  // phone_number: 
  // country_code: 
  // address: 
  // signature:  
  name: string()
    .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
    .min(3, "Minimum 3 characters required")
    .required("Name is required"),
  phone_number: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
  address: string()
    .min(3, "Minimum 3 characters required")
    .required("Address is required"),
});

export const resetDoctoPassword = object({
  password: string()
      .required("Current Password is required"),
      newPassword: string()
      .required('Password is required')
      .min(8, "Minimum 8 characters required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmPassword: string()
      .min(7, "Minimum 7 characters required")
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match.") .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
});