import { all } from 'redux-saga/effects';
import { authSaga } from '../auth/authSaga';
import { userSaga } from '../user/userSaga';
import { appointmentSaga } from '../appointment/patient/patientAppointmentSaga';
import { doctorAppointmentSaga } from '../appointment/doctor/doctorAppointmentSaga';
import { scheduleSaga } from '../schedule/doctor/doctorScheduleSaga';
import { publicSaga } from '../public/publicSaga';
import { doctorSaga } from '../user/doctor/doctorSaga';

export default function* rootSaga() {
    yield all([authSaga(), userSaga(), appointmentSaga(), doctorAppointmentSaga(), scheduleSaga(),publicSaga(),doctorSaga()]);
}