import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";

const useStyles = makeStyles(() => ({
  tableCellStyle: {
    height: 38,
    background: colors.white4,
    borderWidth: "1px 0px",
    borderStyle: "solid",
    borderColor: colors.white1
  },
  viewDetailStyle: {
    colors: colors.primary
  },
  tableContainer: {
    maxHeight: 330
  },
  buttonStyle: {
    height: 38,
    width: '100%',
    textTransform: "none",
    background: colors.primary,
    color: colors.white
  },
  textStyle: {
    fontSize: 14,
    background: colors.primary,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  iconStyle: {
    fontSize: 20,
    color: colors.primary,
    background: colors.primary,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  selectedRow: {
    width: "100%",
    background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.08) 0%, rgba(42, 171, 108, 0.08) 100%)',
    transition: `linear-gradient(90deg, rgba(9, 126, 183, 0.08) 0%, rgba(42, 171, 108, 0.08) 100%) 0.3s ease`
  },
  cursorStyle:{
    cursor: "pointer"
  },
  imageStyle: {
    width: 40,
    height: 40
  },
  statusStyle: {
    padding: "4px 8px",
    borderRadius: 8,
    color: "#fff",
  },
  detailCont: {
    display: "flex"
  },
  paidStyle : {
    color: '#389A8F',
    borderRadius: '32px',
    background: 'rgba(93, 206, 193, 0.20)',
    padding: '2px 8px',
  },
  failedStyle : {
    color: '#F36363',
    borderRadius: '32px',
    background: 'rgba(243, 99, 99, 0.20)',
    padding: '2px 8px',
  },
  refundStyle : {
    color: 'rgb(189, 155, 25)',
    borderRadius: '32px',
    background: 'rgb(255, 236, 139)',
    padding: '2px 8px',
  },
}))

export { useStyles };