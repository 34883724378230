import React, { useState, useRef } from 'react';
import { Modal, Wrapper } from "../styles";
import { StyledCrossIcon, StyledImageCont, StyledMargin, StyledText, StyledSecondaryButton, UploadDocumentStyle, StyledDivider, UploadStyledText, ButtonImage, StyledMainModalCont } from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from '@mui/material';
import { textArray } from "../../CommonSignUpModal/staticData"
import colors from "../../../../constant/colors"
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import Page2 from "../Doctor/Page2";
import DoctorSignUpSteps from './commonComponents/DoctorSignUpSteps';

const Page1 = ({ isSignUpModal, setSignUpModal, pages, buttonLoading, setPageNavigation, toggleSignUp }) => {
  const inputRefs = useRef([]);

  const [image, setImage] = useState([]);
  const [fileName, setFileName] = useState("No selected file")
  const classes = useStyles();

  const handleBack = () => {
    setPageNavigation((page) => ({ ...page, first: true, second: false }))
  }

  const handleForward = () => {
    setPageNavigation((page) => ({ ...page, second: false, third: true }))
  }

  const handlePlusClick = (index) => {
    inputRefs.current[index].click();
  }

  const handleImageUpload = (e, index) => {
    const files = e.target.files;
    if (files && files[0]) {
      const newImage = [...image];
      newImage[index] = URL.createObjectURL(files[0]);
      setImage(newImage);
      setFileName(files[0].name);
    }
  }

  return (
    (pages.second) ?
      <StyledMainModalCont>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setSignUpModal(false)}
          />
        </StyledImageCont>
        <LogoIcon />

        <DoctorSignUpSteps
          image1={"/assets/img/fullFilledImg1.png"}
          image2={"/assets/img/halfFilledImg2.png"}
          image3={"/assets/img/step3Img.png"}
          image4={"/assets/img/step4Img.png"}
          lineStyle={[1, 0, 0]}
        />

        <Grid container spacing={2} marginTop={"16px"}>
          {textArray.map((textItem, index) =>
            <Grid item xs={6} key={index}>
              {image[index] ? (
                <div style={{ textAlign: "center" }}>
                  <img src={image[index]} alt="filesName" width={"188px"} height={"96px"}></img>
                </div>
              ) : (
                <UploadDocumentStyle onClick={() => handlePlusClick(index)}>
                  <div>
                    <img
                      src={"/assets/img/plus.png"}
                      alt="plus"
                      style={{ height: "32px", width: "32px" }}
                    />
                  </div>
                  <UploadStyledText>
                    {textItem}
                  </UploadStyledText>
                </UploadDocumentStyle>
              )}
              <input
                type="file"
                placeholder={textItem}
                ref={(el) => (inputRefs.current[index] = el)}
                hidden
                onChange={(e) => handleImageUpload(e, index)}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} padding="20px 0 0 0">
          <Grid item xs={6}>
            <div className={classes.buttonContainerLeft}>
              <StyledSecondaryButton
                disabled={buttonLoading}
                variant="contained"
                onClick={handleBack}
              >
                {buttonLoading ? (
                  <CircularProgress
                    style={{ color: "white" }}
                    size={20}
                    thickness={5}
                  />
                ) : (
                  <ButtonImage
                    src={"/assets/img/back.png"}
                    alt="backButton"
                  />
                )}
              </StyledSecondaryButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.buttonContainerRight}>
              <StyledSecondaryButton
                disabled={buttonLoading}
                variant="contained"
                onClick={handleForward}
              >
                {buttonLoading ? (
                  <CircularProgress
                    style={{ color: "white" }}
                    size={20}
                    thickness={5}
                  />
                ) : (
                  <ButtonImage
                    src={"/assets/img/forward.png"}
                    alt="forwardButton"
                  />
                )}
              </StyledSecondaryButton>
            </div>
          </Grid>
        </Grid>

        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>
            {strings.alreadyAccount}{' '}
            <span className="sign-color" onClick={toggleSignUp}>
              {strings.login}
            </span>
          </StyledText>
        </StyledMargin>

      </StyledMainModalCont>
      : <Page2
        pages={pages}
        setPageNavigation={setPageNavigation}
        buttonLoading={buttonLoading}
        isSignUpModal={isSignUpModal}
        setSignUpModal={setSignUpModal}
        toggleSignUp={toggleSignUp}
      />
  )
}

export default Page1;
