import { makeStyles } from '@mui/styles';

import colors from '../../constant/colors';
import fontWeights from '../../constant/fontWeights';

const useStyles = makeStyles((theme) => ({
  commonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  mainCont: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: colors.white,
    padding: 20,
    borderRadius: 5,
    maxHeight: "40vmax",
    maxWidth: '50vmax',
    minWidth: "50max",
    overflow: "auto",
    display: "flex-wrap" 
  },
  padding: {
    padding: 40
  },
 input:{
backgroundColor:"gray"
 },
  mainTextStyle: {
    width: "100%",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight400,
    fontSize: 20,
    marginTop: 16,
    lineHeight: "42px",
    color: colors.black,
    '@media only screen and (max-width: 414px)': {      
      fontSize:20
    },    
   },
  radioStyle: {
    marginTop: 5
  },
  errorTextStyle: {
    color: colors.errorTextColor,
    fontSize: 12,
    fontFamily: "Arial",
  },
  strikeStyle: {
    height: 32,
    display: 'flex',
    alignItems: "center",
    textAlign: "center"
  },
  logoStyle: {
    width: '200px',
    height: '46px'
  },
  logoCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '@media (max-width: 600px)': {
    backgroundColor: 'yellow',}
}));

export { useStyles };