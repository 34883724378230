import React, { useContext, useEffect, useState } from 'react';
import { StyledContainer, StyledButtonPay, StyledSlots, Link } from './styles';
import moment from 'moment';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import CardComponent from '../../../../components/Card';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_DATES_APPOINTMENTS
} from '../../../../store/types';
import Slider from "react-slick";
import {
	getDoctorInfo,
} from '../../../../api/organization';
import { getMedicalHistoryDataAPI } from '../../../../api/doctor';
import {
	getDoctorSlotsListingAPI,
	reBookAppointmentAPI
} from '../../../../api/patient';
import {
	getAppointmentsOrg
} from '../../../../api/subadmin';
import '../IpdWard/Patient/appointment.css';
import PatientInfo from '../../Components/PatientInfo';
import AppointmentsBox from '../../../../components/Appointments';

import IpdList from '../../../../components/IpdList';
import SubAdminAddPatient from '../Patient/AddPatient';
import PatientFlow from '../../../../components/PatientFlow';
import PatientSelectModal from '../../../../components/PatientFlow/PatientSelectModal';
import { useHistory } from 'react-router-dom';

function SubAdminDoctor(props) {
	const today = moment().format('DD/MM/YYYY');
	const todayPlusOne = moment().add(1, 'days');
	const [state, dispatch] = useContext(Context);
	const [historyAppointments, setHistoryAppointments] = useState([])
	const [ipdHistory, setIpdHistory] = useState([]);
	const [isPatietnInIpd, setIsPatietnInIpd] = useState(true)
	const [slots, setSlots] = useState([]);
	const [wardRoomPatients, setWardRoomPatients] = useState([]);
	const [todayAppointment, setTodayAppointment] = useState([]);
	const [upcomingAppointment, setUpcomingAppointment] = useState([]);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [createNewPatientModal, setCreateNewPatientModal] = useState(false);
	const [searchPatientModal, setSearchPatientModal] = useState(false);
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'))
	const [selectedSlot, setSelectedSlot] = useState({ _id: null, slot: null, date: null });
	const history = useHistory();
	const [apptPrice, setApptPrice] = useState({
		onlineAmount: 0,
		offlineAmount: 0
	});
	const [curreny, setCurrency] = useState('');
	const [doctorInfo, setDoctorInfo] = useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3
	};


	const [medicalHistory, setMedicalHistory] = useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});
	const [serviceDetails, setServicesDetails] = useState({
		serviceDate: '',
		serviceName: '',
		serviceAmount: '',
		serviceAddedBy: JSON.parse(localStorage.getItem('subuser'))._id,
		addedByApi: false,
	})

	const getAppointmentData = () => {
		loader(true);
		getAppointmentsOrg().then(res => {
			const fetchTodayAppointment = res?.data.filter((item) => item.doctorId._id == props.match.params.id && moment(item.slot.start).utc().format('DD/MM/YYYY') == today);
			const fetchUpcomingAppointment = res?.data.filter((item) => item.doctorId._id == props.match.params.id && moment(item.slot.start) >= todayPlusOne);
			setTodayAppointment(fetchTodayAppointment);
			setUpcomingAppointment(fetchUpcomingAppointment)
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	useEffect(() => {
		getAppointmentData();
		Cookies.remove('appointment', { path: '' });
		Cookies.remove('slot', { path: '' });
		Cookies.remove('doctorInfo', { path: '' });
		Cookies.remove('setPatientInfo', { path: '' });
		if (props.match.params && props.match.params.id) {
			loader(true);

			getDoctorInfo(props.match.params.id).then((res) => {
				loader(false);
				setDoctorInfo({
					dob: res.data.dob,
					email: res.data.email,
					gender: res.data.gender,
					phoneNumber: res.data.phoneNumber,
					name: res.data.name,
					address: res.data.address,
					profileImage: res.data.profileImage,
					org_name: res.data.org_name,
					_id: res.data._id,
					weight: res.data.weight
				})
				setApptPrice({
					onlineAmount:  res.data.appointmentPrice ? res.data.appointmentPrice : 0,
					offlineAmount: res.data.clinicPrice ? res.data.clinicPrice : 0,
						  })
						  setCurrency(res.data.curreny)
			}).catch(err => {
				loader(false);
				if (err?.response?.data?.error) return toast.error(err.response.data.error);
			})
			getDoctorSlotsListingAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});


				var slotMap = {};
				for (let slot of res.data) {
					const date = moment(slot.start).utc().format('DD/MM/YYYY');
					if (moment(slot.start).utc().format() > moment().format()) {
						if (slotMap[date]) {
							slotMap[date].push(slot);
						}
						else {
							slotMap[date] = [slot];
						}
					}

				}

				var result = Object.keys(slotMap).map(slotDate => {
					return {
						date: slotDate,
						slots: slotMap[slotDate]
					}
				});

				setSlots(result);
			}).catch(err => {
				loader(false);
				if (err?.response?.data?.error) {
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				}
			})
		}
		if (props.location && props.location.state) {
			const wardRoom = props.location.state.wardRoomPatients?.length ? props.location.state.wardRoomPatients.filter((item) => item.doctor._id === props.match.params.id) : []
			const todayApp = props.location.state.todayAppointment?.length ? props.location.state.todayAppointment.filter((item) => item.doctorId._id === props.match.params.id) : []
			const upcomingApp = props.location.state.upcomingAppointment?.length ? props.location.state.upcomingAppointment.filter((item) => item.doctorId._id === props.match.params.id) : []
			setWardRoomPatients(wardRoom);
			// setTodayAppointment(todayApp);
			// setUpcomingAppointment(upcomingApp);
		}
	}, [])

	const setSlot = (value) => {
		setSelectedSlot({ _id: value.slot._id, slot: value.slot, date: value.date });
	}
	const openIpdPatientView = (id) => {
		props.history.push(`/subuser/view-patient/${id}/${props.match.params.id}`)
	}
	const handleClickAppointment = (id) => {
		props.history.push(`/subuser/view-appointment/${id}`);
	}

	const gotoCheckout = () => {
		if (selectedSlot._id && selectedSlot.date) {
			if (selectedSlot.type === 1) {
				if (apptPrice.onlineAmount <= 0) {
					toast.error("You can't book this appointment")
					dispatch({
						type: SET_ERROR,
						payload: "You can't book this appointment"
					});
					return
				}
			} else {
				if (apptPrice.offlineAmount <= 0) {
					toast.error("You can't book this appointment")
					dispatch({
						type: SET_ERROR,
						payload: "You can't book this appointment"
					});
					return
				}
			}

			Cookies.set('slot', JSON.stringify(selectedSlot.slot), { path: '' });

			dispatch({
				type: SET_DATES_APPOINTMENTS,
				payload: {
					slot: selectedSlot.slot,
					date: selectedSlot.date,
					doctor: props.match.params.id,
					// patientId: null
				}
			});
			setShowCheckoutModal(true)
			document.body.style.overflowY = 'hidden';
			// props.history.push(`/subuser/book-appointment-form`, {
			// 	showPatientIdField: true
			// });

		}
	}

	const handleSelectPatient = (id) => {
		props.history.push(`/subuser/book-appointment-form`,{
			showIdField:true
		});
	}

	const handleExistingPatientClick = () => {
		// const [createNewPatientModal, setCreateNewPatientModal] = useState(false);
		// const [searchPatientModal, setSearchPatientModal] = useState(false);
		setShowCheckoutModal(false);
		setSearchPatientModal(true);
	}

	const handleCreateNewPatient = () => {
		setShowCheckoutModal(false);
		setCreateNewPatientModal(true);
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="col-md-12 row">
								<Link onClick={()=> history.push('/subuser/dashboard')} > <i className="fa fa-arrow-left pr-2" /> Back To Dashboard </Link>
								{/* {!isPatietnInIpd ?
									<div className='ml-auto d-flex'>
										<Link
											to={`/subuser/doctor-listing/${props.match.params.id}`}
											style={{
												border: '1px solid #5156be',
												borderRadius: '5px',
												padding: '10px',
												marginBottom: '20px',
												color: '#FFF',
												backgroundColor: "#5156be",
												fontSize: '17px',
												fontWeight: '400',
												marginRight: "20px"
											}}
										>
											Book an appointment
										</Link>
										<Link
											to={`/subuser/ipdward/add-patient/${props.match.params.id}`}
											style={{
												border: '1px solid #5156be',
												borderRadius: '5px',
												padding: '10px',
												marginBottom: '20px',
												backgroundColor: "#5156be",
												color: '#FFF',
												fontSize: '17px',
												fontWeight: '400'
											}}
										>
											Admit patient into IPD
										</Link>
									</div> : null
								} */}
							</div>

							<h3 className="mb-4 mt-4">Doctor details </h3>
							<StyledContainer>
								<div className="content">
									<div className="container-fluid pr-0">
										<div className="container-fluid-card row p-0 d-flex col-md-12">
											<div className='col-md-9 row'>
												<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
													<AppointmentsBox
														singleCard={true}
														todayAppointment={todayAppointment}
														upcomingAppointment={upcomingAppointment}
														height={"255px"}
														showTotal={true}
													/>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
													<CardComponent className="mb-4 current-vitals-chart-card">
														<div className="d-flex row ml-1 current-vitals-chart-card-heading">
															<h5 className="pt-3 pl-3">Current IPD ward patients</h5>
														</div>
														<hr />
														<ul className="list-unstyled p-3" style={{ height: "255px", overflow: "auto" }} >
															{
																wardRoomPatients.length ? wardRoomPatients && wardRoomPatients.map((item) => (
																	<li className="media received d-flex m-0 mb-1 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
																		<div class="media mt-0">
																			<img
																				className="avatar-lg rounded-circle"
																				src={item?.patient?.profileImage || "/assets/img/loading-img.jpg"}
																				alt="User"
																			/>
																			<div class="media-body">
																				<div class="d-flex align-items-center">
																					<div class="mt-0">
																						<h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
																							{item.patient.name}
																						</h5>
																						<p class="mb-0 tx-12 text-muted">Ward no: {item.roomWard.roomNo} ({item.roomWard.roomType})</p>
																					</div>
																					<span class="ml-auto">
																						<span class="float-end badge badge-success-transparent" onClick={() => props.history.push(`/subuser/view-patient/${item._id}/${item.patient._id}`)}>
																							View
																						</span>
																						{/* <Link to={`/subuser/view-patient/${item._id}/${item.patient._id}`} className="float-end badge badge-success-transparent">
																					View
																				</Link> */}
																					</span>
																				</div>
																			</div>
																		</div>
																	</li>
																))
																	: <div className="d-flex justify-content-center" style={{ marginTop: "15%", textAlign: "center", padding: "20px" }}>Sorry! There are no Patients to display</div>
															}
														</ul>
														<div className="d-flex ml-1 mr-3 mt-2 mb-2">
															<h5>
																Total patients: {wardRoomPatients ? wardRoomPatients.length : 0}
															</h5>
														</div>
													</CardComponent>
												</div>
												<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
													<CardComponent className="p-4 mb-4">
														<div className="row mt-3">

															<div className="col-12 col-sm-4 col-md-6">

																<h4 className="mb-1">Today - {moment().format('Do MMMM YYYY')}</h4>
																<p className="text-muted">{moment().format('dddd')}</p>
															</div>
														</div>
														<hr />
														<div className="row mb-3">
															<div className="col-md-4 mb-2"><b>Online appointment -</b>  <b style={{ background: '#2c7fb7', paddingLeft: '10px', color: '#fff', padding: '3px 20px' }}></b></div>
															{/* Blue color */}
															<div className="col-md-4 mb-2"><b>In clinic appointment - </b> <b style={{ background: '#56be73', paddingLeft: '10px', color: '#fff', padding: '3px 20px' }}></b></div>
															{/* Green colors */}
															<div className="col-md-4 mb-2"><b>Selected slot -  </b><b style={{ background: 'red', paddingLeft: '10px', color: '#fff', padding: '3px 20px' }}></b></div>
															{/* Red colors */}
														</div>

														<hr />
														<div className="row mb-3">
															<div className="col-md-6"><b>Online appointment price -</b> <b style={{ color: '#2c7fb7', paddingLeft: '10px' }}>{curreny} {apptPrice.onlineAmount}</b></div>
															<div className="col-md-6"><b>In clinic appointment price -</b> <b style={{ color: '#56be73', paddingLeft: '10px' }}>{curreny} {apptPrice.offlineAmount}</b></div>

														</div>

														<div class="schedule-header">
															<div class="row">
																<div class="col-md-12">

																	<Slider {...settings}>
																		{
																			slots && slots.map((item, i) => {
																				return <div className="day-slot">
																					<ul>
																						<li key={i} className="li-doc">
																							<span>{item.date}</span>
																							<ul className="doc-slot">
																								{
																									item.slots && item.slots.map((slot, index) => {
																										return <li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected-slot' : (
																											slot.type === 1 ? 'slot-online' : 'slot-offline'
																										)} onClick={() => permissions.includes("Admin") || permissions.includes("OPD") ? setSlot({ slot: slot, date: item.date }) : null}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																									})
																								}
																							</ul>
																						</li>
																					</ul>
																				</div>
																			})
																		}
																	</Slider>

																	{
																		slots.length === 0 && (
																			<h5>There is no slot available</h5>
																		)
																	}
																</div>
															</div>
														</div>


														<div class="submit-section proceed-btn text-right">
															{
																slots.length > 0 && (
																	<StyledButtonPay class="btn btn-primary submit-btn"  onClick={gotoCheckout} >
																		Proceed
																	</StyledButtonPay>
																)
															}

														</div>
													</CardComponent>
												</div>

											</div>
											<div className='col-md' style={{ padding: "0px" }}>
												<PatientInfo patientInfo={doctorInfo} title="View doctor info" modalTitle="Doctor info" />
											</div>
										</div>
									</div>
								</div>
							</StyledContainer>
						</div>
					</div>
				</div>
			</div>
			{/* onClick={gotoCheckout} */}
			{
				showCheckoutModal && 
				<PatientSelectModal
					setShowModal={setShowCheckoutModal}
					handleExistingPatientClick={handleExistingPatientClick}
					handleCreateNewPatient={handleCreateNewPatient}
				/>
			}
			{
				createNewPatientModal &&
				<SubAdminAddPatient
					setModalVisible={setCreateNewPatientModal}
					isPatientFlow={true}
					handleProcced={handleSelectPatient}
				/>
			}
			{
                searchPatientModal &&
               <PatientFlow
				setModalVisible={setSearchPatientModal}
				handleProcced={handleSelectPatient}
				/>
            }
		</div>
	)
}
export default SubAdminDoctor;