import React , {useState} from 'react';
import { Modal, Wrapper } from "../styles";
import { StyledCrossIcon, StyledDivider, StyledFormik, StyledImageCont, StyledMargin, StyledText, StyledButton, SuccessLogo, StyledMainModalCont} from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from '@mui/material';
import { useStyles } from '../../../../assets/styles/RadioButtonStyles';
import {StyledTextBold, StyledTextContent} from "../../../../assets/styles/SuccessPageStyles";
import DoctorSignUpSteps from './commonComponents/DoctorSignUpSteps';



const Page4 = ({signUpModal, setSignUpModal, pages, setPageNavigation, buttonLoading}) => {
  const classes = useStyles();
  

  const handleBack=()=>{
    setPageNavigation((page)=>({...page, fifth: false, forth:true}))
  }
    
  return (
    (pages.fifth) ?
    <StyledMainModalCont>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={()=>setSignUpModal(false)}
          />
        </StyledImageCont>
        <LogoIcon />

        <DoctorSignUpSteps
        image1={"/assets/img/fullFilledImg1.png"}
        image2 = {"/assets/img/fullFilledImg2.png"}
        image3= {"/assets/img/fullFilledImg3.png"}
        image4 = {"/assets/img/halfFilledImg4.png"}
        lineStyle={[1,1,1]} 
        />
        <SuccessLogo >
          <img src="/assets/img/success.png" width="184px" height="187px" alt="success" />
        </SuccessLogo>

        <StyledMargin>
          <StyledTextBold>
            {strings.congratulations} Rishabh!
          </StyledTextBold>
        </StyledMargin>

        <StyledMargin>
        <StyledText>We have send you an email at<span style={{fontWeight: "600"}}> atrishabh1234@yahoo.com</span> please check for futher details. Also your 
          <span style={{fontWeight: "600"}}> 30-days</span> trial period will start after your verification.</StyledText>
        </StyledMargin>

        
        
        

        <Box m={1} pt={1} >
                <div className={classes.buttonContainer}>
                <StyledButton
                  disabled={buttonLoading}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : strings.continue}
                </StyledButton>
                </div>
              </Box>
        
        
      </StyledMainModalCont> : null
  )
}

export default Page4;