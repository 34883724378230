import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { patientAppointmentHeadingList, patientAppointmentHeadingListTab3,  patientAppointmentHeadingListTab4 } from "../../../constant/staticLists";
import * as mainService from "../../../services/mainService.js";
import strings from "../../../constant/strings";
import { toast } from "react-toastify";
import Tables from "../../../components/controls/Tables";
import { LoadingContainer, NoDetailsContainer } from "../../../assets/styles/CommonStyles";

const AppointMentList = ({ searchValue, tabValue, setBookAppointment, setStep}) => {
  const { userData, patientAppointmentList, loading } = useSelector((state) => ({
    userData: state.auth.userData,
    patientAppointmentList: state.appointment.patientAppointmentList || [],
    loading : state.appointment.loading
  }));
  const [list, setList] = useState([]);

  useEffect(() => {
    if (searchValue !== "") {
      let list = patientAppointmentList.filter((item) => item.doctorInfo.name.toLowerCase().includes(searchValue))
      setList(list);
    } else {
      setList(patientAppointmentList)
    }
  }, [patientAppointmentList, searchValue])

  const handleCancleAppointment = async (id) => {
    let value = { token: userData.token, data: { appointmentId: id } }
    const res = await mainService.cancelAppointmentByPatientApi(value);
    if (res.response_type === "fail") {
      //toast.error(res?.response?.message, { position: "bottom-center", closeButton: false })
    } else {
      //toast.success(res?.response?.message, { position: "bottom-center", closeButton: false })
      let listArray = list.filter((item) => item._id !== id);
      setList(listArray);
    }
  }

  const handleBookAgain = () => {
    setBookAppointment(1)
  }

  return (<>
  {loading ? <LoadingContainer>{strings.loading}</LoadingContainer> : !!list && list.length > 0 ? (
      <Tables
        data={list}
        handleCancleAppointment={handleCancleAppointment}
        handleBookAgain = {handleBookAgain}
        fieldsOrder={(tabValue === 0 || tabValue=== 1) ? patientAppointmentHeadingList : tabValue === 2 ? patientAppointmentHeadingListTab3 : patientAppointmentHeadingListTab4}
      />) :
      <NoDetailsContainer>{strings.noAppointmentAvailable}</NoDetailsContainer>
  }</>
    
  );
};

export default React.memo(AppointMentList);