import React , {useState} from 'react';
import { Modal, Wrapper } from "../styles";
import { StyledCrossIcon, StyledDivider, StyledFormik, StyledImageCont, StyledMargin, StyledText, StyledSecondaryButton, ButtonImage, StyledMainModalCont, GridBox} from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from '@mui/material';
import Page4 from "../Doctor/Page4"
import { useStyles } from '../../../../assets/styles/RadioButtonStyles';
import DoctorSignUpSteps from './commonComponents/DoctorSignUpSteps';


const Page3 = ({pages, setSignUpModal, setPageNavigation, isSignUpModal, buttonLoading, toggleSignUp}) => {
  const classes= useStyles();

  const handleForward=()=>{
    setPageNavigation((page)=>({...page, forth: false, fifth: true}))
  }
  const handleBack=()=>{
    setPageNavigation((page)=>({...page,  forth: false, third: true}))
  }
    
  return (
    (pages.forth)?
    <StyledMainModalCont>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={()=> setSignUpModal(false)} 
          />
        </StyledImageCont>
        <LogoIcon />
        
        <DoctorSignUpSteps
        image1={"/assets/img/fullFilledImg1.png"}
        image2 = {"/assets/img/fullFilledImg2.png"}
        image3= {"/assets/img/fullFilledImg3.png"}
        image4 = {"/assets/img/halfFilledImg4.png"}
        lineStyle={[1,1,1]} 
        />

        <GridBox></GridBox>

        <Grid container spacing={2} padding="20px 0 0 0">
          <Grid item xs={6}>
          <div className={classes.buttonContainerLeft}>
                <StyledSecondaryButton
                  disabled={ buttonLoading}
                  variant="contained"
                  onClick={handleBack}>
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : (<ButtonImage
                    src={"/assets/img/back.png"}
                    alt= "backButton"
                    ></ButtonImage>)}

                </StyledSecondaryButton>
                </div>

          </Grid>
          <Grid item xs={6}>
          <div className={classes.buttonContainerRight}>
                <StyledSecondaryButton
                  disabled={ buttonLoading}
                  variant="contained"
                  onClick={handleForward}>
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : (<ButtonImage
                    src={"/assets/img/forward.png"}
                    alt= "forwardButton"
                    ></ButtonImage>)}

                </StyledSecondaryButton>
                </div>

          </Grid>
        </Grid>

        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>{strings.alreadyAccount}{' '}
            <span className="sign-color" onClick={toggleSignUp}>{strings.login}</span></StyledText>
        </StyledMargin>
        
        
      </StyledMainModalCont> : <Page4 
    pages={pages}
    setSignUpModal={setSignUpModal}
    setPageNavigation={setPageNavigation}
    isSignUpModal={isSignUpModal}
    buttonLoading={buttonLoading}
    toggleSignUp={toggleSignUp}
     /> 
  )
}

export default Page3;