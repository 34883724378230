export default {
  white: "#ffffff",
  black: "#000000",
  black1: "#2B2B2B",
  black2: "#1C2134",
  grey: "#CCCCCC",
  grey1: "#5E5E5E",
  grey2: "#333333",
  red: "#D3F2F",
  green: "#2AAB6C",
  white1: "#F5F5F7",
  white2: "#f0f0f0",
  white3: "#F8F7F7",
  white4: "#F8F9FC",
  // errorTextColor: "#9F215F",
  errorTextColor: "#D32F2F",
  warning: "#F36363",
  primary: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
  blackGradient: "linear-gradient(0deg, #000000, #000000)",
  shadowGradient: "rgba(192, 192, 192, 0.25)"
}