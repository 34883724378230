import { makeStyles } from "@mui/styles";
import colors from "../../../../../../constant/colors"
import fontWeights from "../../../../../../constant/fontWeights";

const useStyles = makeStyles(() => ({

    root: {
        "& .MuiFormControlLabel-root": {
          marginLeft: "0px",
          marginBottom: "16px",
        }},
        
    checkBoxContainer: {
        marginTop: "16px",
        marginBottom: "16px",
        border: `2px solid ${colors.black2}`,
        borderRadius: "8px",
        alignItems: "center",
        padding: "8px",
        
    },

    checkedCheckbox : {
        marginTop: "16px",
        marginBottom: "16px",
        border: '2px solid green',
        borderRadius: "8px",
        alignItems: "center",
        padding: "8px",
        
    },

    imageContainer: {
        height: "16px",
        weight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    }

  
}));

export { useStyles };
