import { Grid, Link } from "@mui/material";
import React, { useEffect } from "react";
import strings from "../../../../constant/strings";
import { dashoboardUseStyles } from "../../../../assets/styles/DoctorDashboardStyles";
import { getPatientDetailsForDoctorAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContainer } from "../../../../assets/styles/CommonStyles";

const patientInf = {
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Contact Number',
  gender: "Gender",
  dob: "Date of birth",
  address: 'Address',
  weight: 'Weight',
  height: "Height",
  appointmentSymptoms: "Symptoms",
  appointmentDocuments : "Documents",

};

const renderValue = (label, value) => {
  if ( label === "Gender"){
    switch(value){
      case 1: return "Male";
      case 2: return "Female";
      case 3: return "Other";
      default: return value;
    }
  }
if (label === "Symptoms"){
 return value.join(', ')
}
if ( label === "Documents") {
  const arr = value.map((item, index) => <Link href = {item} target= "_blank" className="classes.linkStyle" style={{textDecoration: 'none'}}>Document{`${index+1}`} {" "}</Link> )
  return arr
}
  else return value;
}

const InfoGridItem = ({ label, value }) => {
  const classes = dashoboardUseStyles();
  return (<>
  {
    label === "Symptoms" || label === "Documents" ? <Grid item xs={12} className={classes.innerTextCont}>
    <span className={classes.labelStyle}>{label}:</span>
    <span className={`${classes.labelStyle} ${classes.valueStyle}` }>{renderValue(label, value)}</span>
    </Grid> :
    <Grid item xs={12} sm={6} lg={3} className={classes.innerTextCont}>
    <span className={classes.labelStyle}>{label}:</span>
    <span className={`${classes.labelStyle} ${classes.valueStyle}`}>{renderValue(label, value)}</span>
  </Grid> 
  }
  </>   
  );
};

const PatientInformation = ({ item }) => {
  const classes = dashoboardUseStyles();

  const { patientDetails, patientInfoListLoading } = useSelector((state) => ({
    patientDetails: state.doctorAppointment.patientInfo,
    patientInfoListLoading: state.doctorAppointment.patientInfoListLoading
  }));

  return (
    <Grid container spacing={0} className={classes.mainCont}>
      <Grid item xs={12} className={classes.headingTextStyle}>
        {strings.patientInformation}
      </Grid>
      {patientInfoListLoading ? <LoadingContainer>{strings.loading}</LoadingContainer> : Object.keys(patientInf).map(key => (
        !!patientDetails &&
        <InfoGridItem key={key} label={patientInf[key]} value={patientDetails[key]} />
      ))}
    </Grid>
  )
}

export default PatientInformation;
