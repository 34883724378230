import React from "react";
import { useState } from "react";
import './style.css';
import { StyledButton, StyledCrossIcon, StyledDivider, StyledFormik, StyledImageCont, StyledMainModalCont, StyledMargin, StyledText } from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from '@mui/material';
import { Formik } from "formik";
import { signUpSchema } from "../../../../utils/forms/schema.signUpSchema";
import PhoneField from "../../../PhoneField";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import { oneSpace, textString } from "../../../../utils/TextTransform";

const NormalUser = ({ isSignUpModal, setSignUpModal, toggleSignUp, handleSubmit={}, buttonLoading }) => {
  const [orgUser, setOrgUser] = useState({ name: '', phoneNumber: '', email: '', password: '', countryCode: "" });
  return (
    <StyledMainModalCont visible={isSignUpModal}>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() =>setSignUpModal(false)}
          />
        </StyledImageCont>
        <LogoIcon />
        <Formik
          initialValues={orgUser}
          validationSchema={signUpSchema}
          onSubmit={() => handleSubmit(orgUser, buttonLoading)}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            touched,
            setTouched,
            handleSubmit,
          }) => (
            <StyledFormik>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="fullName"
                    type="text"
                    placeholder={`${strings.fullName}`}
                    value={orgUser.name}
                    onChange={e => {
                        handleChange(e);
                        setOrgUser( (user) => ({ ...user, name: textString(e.target.value.split('').filter(oneSpace).join(''))}));
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="email"
                    placeholder={`${strings.emailAddress}`}
                    type="email"
                    value={orgUser.email}
                    onChange={e => {
                      handleChange(e)
                      setOrgUser((user) => ({ ...user, email: e.target.value }))
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneField
                    value={orgUser.phoneNumber}
                    name="phoneNumber"
                    onChange={(e, value) => {
                      handleChange("phoneNumber")(e);
                      setOrgUser((user) => ({ ...user,  phoneNumber:e, countryCode: `+${value.dialCode}` }))
                    }}
                    onBlur={() => setTouched({ ...touched, phoneNumber: true })}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldWrapper
                    name="password"
                    placeholder={`${strings.password}`}
                    value={orgUser.password}
                    onChange={e => {
                      handleChange(e)
                      setOrgUser((user) => ({ ...user, password: e.target.value }))
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldWrapper
                    name="confirmPassword"
                    placeholder={`${strings.confirmPassword}`}
                    value={orgUser.confirmPassword}
                    onChange={e => {
                      handleChange(e)
                      setOrgUser((user) => ({ ...user }))
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Box m={1} pt={1}>
                <StyledButton
                  disabled={Object.keys(errors).length > 0 || buttonLoading}
                  onClick={handleSubmit}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : "Sign up"}

                </StyledButton>
              </Box>
            </StyledFormik>
          )}
        </Formik>
        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>{strings.alreadyAccount}{' '}
            <span className="sign-color" onClick={toggleSignUp}>{strings.login}</span></StyledText>
        </StyledMargin>
    </StyledMainModalCont>
  )
}

export default NormalUser
