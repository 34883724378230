import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
//import Appointments from "../../components/ui/Doctor/Appointments";

const OrganisationPatientList = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        {/* <Appointments /> */}
        <div>Patient</div>
      </AppLayout>
    </Grid>
  )
}

export default OrganisationPatientList;