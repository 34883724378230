import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import styled from 'styled-components';
import defaultUserPng from '../../../assets/img/user.png'
import { Box } from '@mui/system';
import PersonalDetails from './PersonalDetails';
import ResetPassword from './ResetPasswords';
import EmergencyContact from './EmergencyContact';
import { useDispatch, useSelector } from 'react-redux';
import { patientProfile } from '../../../redux/user/userActions'
import { updateProfile } from '../../../services/authService';

const HeadingTypography = styled(Typography)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #5E5E5E;
`
const UserImage = styled('img')`
  height: 88px;
  width: 88px;
  border-radius: 50%;
`

const StyledTabs = styled(Tabs)(({ theme, selectedItem, index }) => ({
    borderBottom: '1.2px solid #F5F5F7',
    '& .MuiTab-root': {
        justifyContent: 'start',
        alignItems: 'start',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '18px',
        color: '#5E5E5E',
    },
    '& .Mui-selected': {
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        border: '1px solid',
        borderImageSlice: 1,
        borderWidth: '0px 0px 1.2px',
        borderImageSource: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)'
    }
}))

const LinearGradianForSvg = React.memo(() => (
    <svg style={{
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        zIndex: -1
    }}>
        <defs>
            <linearGradient id="header-shape-gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stop-color="rgba(9, 126, 183)"></stop>
                <stop offset="100%" stop-color="rgba(42, 171, 108)"></stop>
            </linearGradient>
        </defs>
    </svg>
))

const SytledBoxTabs = styled(Box)(() => ({
    '& input': {
        boxSizing: 'border-box',
        background: '#F8F7F7',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        height: 48,
        color: '#2B2B2B',
        border: 'none',
        borderRadius: '8px',
        '& + .MuiInputAdornment-root': {
            position: 'absolute',
            right: 20,
            '& .MuiTypography-root': {
                color: 'color(srgb 0.6361 0.6312 0.6314)',
                paddingRight: '6px',
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
            },
        }
    },
    '& .MuiFormControl-root > label': {
        color: '#CCCCCC',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        marginTop: '-3px'
    },
    '& .MuiFormControl-root > .MuiInputLabel-shrink': {
        marginTop: 0
    },
    '& fieldset': {
        border: '1px solid #CCCCCC',
        borderColor: '#CCCCCC !important',
        borderRadius: '8px',
    },
    '& .gender-select-box': {
        background: '#F8F7F7',
        height: 48
    },
    '& .react-tel-input': {
        borderRadius: '8px !important',
        '& input': {
            height: '46px !important',
            borderRadius: '8px',
            '& + .flag-dropdown': {
                background: '#F8F7F7',
                borderRadius: '8px',
            }
        }
    },
    [`& input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button`]: {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type="number"]': {
        '-moz-appearance': 'textfield'
    },
    '& .react-date-picker': {
        '& .react-date-picker__wrapper': {
            padding: 0,
            border: '1px solid #CCCCCC',
            background: '#F8F7F7',
            borderRadius: '8px',
            height: '48px',
            paddingLeft: '10px',
            '& + span': {
                '& .react-date-picker__calendar--open': {
                    '& .react-calendar__tile--now': {
                        background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.05) 0%, rgba(42, 171, 108, 0.05) 100%)',
                    },
                    '& .react-calendar__tile--active': {
                        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                    }
                },
            }
        },
        '& input': {
            height: '48px',
            background: 'transparent',
            minWidth: 20,
            textAlign: 'center',
            '&:focus': {
                border: 'none'
            }
        },
        '& .react-date-picker__clear-button': {
            display: 'none'
        },
        '& .react-date-picker__calendar-button': {
            '& svg': {
                fill: 'rgba(0, 0, 0, 0.54)'
            }
        }
        
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            // style={{ height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <SytledBoxTabs sx={{ p: 3, height: '100%', pt: 0 }}>
                {children}
            </SytledBoxTabs>
        </div>
    );
}

const PatientProfileSettings = (props) => {
    const [value, setValue] = useState(0)
    const [screenWidth, setSceenWidth] = useState(window.innerWidth)
    const [image, setImage] = useState()
    const [file, setFile] = useState();
    const history = useHistory(null)
    const belowMedium = useMediaQuery('(max-width: 900px)')
    const belowMediumHeight = useMediaQuery('(max-height: 900px)')
    const dispatch = useDispatch();
    const { userData, patientProfileData } = useSelector((state) => ({
        patientProfileData: state.user.patientProfile || {},
        userData: state.auth.userData
    }));
    const ref = React.useRef(null)

    React.useEffect(() => {
        dispatch(patientProfile({ token: userData.token }))
        window.addEventListener('resize', () => setSceenWidth(window.innerWidth))
        return () => window.removeEventListener('resize', () => true)
    }, [])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const uploadImage = async (e) => {
        const [files] = e.target.files;
        setFile(e.target.files[0]);        
        const fileContent = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = function (evt) {
                if (evt.target.readyState != 2) return;
                resolve(evt.target.result)
            };
            reader.readAsDataURL(files);
        })
        setImage(fileContent)
        
        // await updateProfile({ profileImage: fileContent }, userData.token)
        // dispatch(patientProfile({ token: userData.token }))
    }

    return (
        <Grid container spacing={4} sx={{ background: '#FFFFFF', marginTop: 0, minHeight: '100vh', paddingTop: 0 }}>
            <LinearGradianForSvg />
            <Grid item xs={12} style={{ paddingTop: 0}}>
                <Grid container spacing={0} justifyContent={'space-between'} alignItems={'center'} sx={{
                    //height: 72,
                    borderBottom: '1.2px solid #F5F5F7',
                }}>
                    <Grid item xs={11} sx={{
                        padding: '8px'
                    }}>
                        <HeadingTypography>
                            Profile Settings
                        </HeadingTypography>
                    </Grid>
                    <Grid item xs={1} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                        <IconButton
                            onClick={() => history.push('/patient/dashboard')}
                        >
                            <ClearIcon
                                sx={{
                                    width: 32,
                                    fill: '#2B2B2B',
                                    cursor: 'pointer'
                                }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignContent="center" display={"flex"} sx={{
                padding: '0px 0px',
                position: 'relative',
                //height: 200,
                '& .file-upload': {
                    zIndex: -1,
                    opacity: 0,
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }
            }}>
                <IconButton
                    onClick={() => ref.current.click()}
                    disableRipple
                    sx={{
                        position: 'absolute',
                        top: '24px',
                        right: 'calc(50% - 75px)'
                    }}>
                    <BorderColorOutlinedIcon sx={{
                        fill: 'url(#header-shape-gradient)',
                    }} />
                    <input type="file" className='file-upload' ref={ref} onChange={uploadImage} accept='image/*' />
                </IconButton>
                <UserImage alt="user-profile" src={image || patientProfileData.profileImage || defaultUserPng} />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '16px', height: '56px' }}>
                <StyledTabs value={value} onChange={handleChange} variant="scrollable">
                    {['Personal details', 'EMERGENCY CONTACTS', 'RESET PASSWORD'].map((item, index) =>
                        <Tab label={item} {...a11yProps(index)} />
                    )}
                </StyledTabs>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '0px'}} sx={{
                '& .form-container': {
                    ...(belowMedium || belowMediumHeight) && { marginTop: '0px' }
                }
            }}>
                <TabPanel value={value} index={0}>
                    <PersonalDetails screenWidth={screenWidth} file={file} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EmergencyContact screenWidth={screenWidth} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ResetPassword screenWidth={screenWidth} />
                </TabPanel>
            </Grid>
        </Grid>
    )
}

export default React.memo(PatientProfileSettings)