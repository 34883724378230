import { FormControlLabel, Grid, Radio, FormControl, RadioGroup } from "@mui/material";
import React, { useState } from 'react'
import { DeptHeading } from '../../../../../../assets/styles/CommonStyles';
import { useStyles } from './RadioButtonStyle';



   

const RadioButtonBox = ({data}) => {
    const classes= useStyles();
    const [selectedValue, setSelectedValue] = useState('');
        
        
  return (
    <div>

      <Grid container spacing={2} >
          {data.map((item, index)=> <Grid item xs={4}>
            <Grid key={index} container className={`${(selectedValue === item.range) ? classes.selectedRadio : classes.radioContainer}`}>
                <FormControlLabel
                control={<Radio checked={selectedValue === item.range } 
                onChange={()=>setSelectedValue(item.range)}  hidden />}
                label= {
             <div >  
            <div className={`${(selectedValue === item.range) ? classes.selectedLabel : classes.radioText}`}> {item.range} </div>
            </div>
                } 
             />
            </Grid>

             
        </Grid>)}


        </Grid>
     
    </div>
  )
}

export default RadioButtonBox;

