import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';

import { CometChatConversationListWithMessages } from '../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import HeadingComponent from '../HeadingComponent';

const fontStyle = {
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
}

const MessageWrapper = styled(Grid)(() => ({
    '& .mainHeading': {
        marginTop: '5px'
    },
    '& .comet-chat-wrapper': {
        minHeight: '70vh',
        '& .cometchat--chats': {
            '& .chats__sidebar': {
                minWidth: '339px',
                '& .contacts__search': {
                    margin: '16px',
                    height: '32px',
                    background: '#FFFFFF',
                    '& .search__button': {
                        backgroundColor: '#FFFFFF',
                        fontSize: '14px',
                        lineHeight: '18px'
                    },
                    '& input': {
                        ...fontStyle,
                        fontWeight: 400,
                        color: '#5E5E5E'
                    }
                },
                '& .list__item': {
                    minHeight: '72px',
                    border: '1.2px solid #F5F5F7',
                    '& .list__item__thumbnail': {
                        marginTop: '-12px',
                        '& > img': {
                            height: '48px',
                            width: '48px',
                            borderRadius: '38px',
                            '& + span': {
                                display: 'none'
                            }
                        },
                        '& + .list__item__details': {
                            marginLeft: '10px',
                            ...fontStyle,
                            '& .item__details__name': {
                                ...fontStyle,
                                fontWeight: 500,
                                color: '#2B2B2B'
                            },
                            '& .item__details__last-message': {
                                ...fontStyle,
                                fontWeight: 400
                            },
                            '& .item__details__timestamp': {
                                ...fontStyle,
                                textAlign: 'right',
                                fontWeight: 400,
                                fontSize: '12px',
                                color: '#5E5E5E'
                            },
                            '& .unread-count': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '18px',
                                height: '18px',
                                borderRadius: '102px',
                                gap: '8px',
                                color: '#FFFFFF',
                                ...fontStyle,
                                lineHeight: '16px',
                                fontWeight: 400,
                                background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                                '& + .item__details__timestamp': {
                                    color: 'yellow'
                                }
                            }
                        }
                    }
                }
            },
            '& .chats__main': {
                '& .main__chat': {
                    '& .chat__header': {
                        borderWidth: 0,
                        height: '64px',
                        // borderWidth: '0px, 0px, 1.2px, 1.2px',
                        borderStyle: 'solid',
                        borderColor: '#F5F5F7',
                        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                        '& .chat__details': {
                            '& .chat__thumbnail': {
                                marginTop: '-12px',
                                '& img': {
                                    borderWidth: '0px !important',
                                    height: '48px',
                                    width: '48px',
                                    borderRadius: '38px',
                                    '& + span': {
                                        display: 'none'
                                    }
                                },
                                '& + .chat__user': {
                                    marginLeft: '10px',
                                    '& .user__name, .user__status': {
                                        ...fontStyle,
                                        color: '#FFFFFF',
                                    },
                                    '& .user__status': {
                                        fontWeight: 400
                                    }
                                }
                            },
                            '& + .chat__options': {
                                display: 'flex',
                                '& .option__videocall-user, .option__audiocall-user': {
                                    '& i': {
                                        background: '#FFFFFF'
                                    }
                                },
                                '& .option__videocall-user': {
                                    order: 2
                                },
                                '& .option__audiocall-user': {
                                    order: 1
                                }
                            }
                        }
                    },
                    '& .chat__list': {
                        background: '#F5F5F7',
                        '& .list__wrapper': {
                            '& .message__deleted': {
                                display: 'none',
                            },
                            '& .message__date span, .action__message p': {
                                ...fontStyle,
                                fontWeight: 400,
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.2) 0%, rgba(42, 171, 108, 0.2) 100%)',
                                minWidth: '80px',
                                minHeight: '24px',
                                padding: '4px 8px 4px 8px',
                                borderRadius: '8px',
                                gap: '8px',
                            },
                            '& .action__message': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& p': {
                                    maxWidth: 'fit-content',
                                }
                            },
                            '& .receiver__message__container, .sender__message__container': {
                                maxWidth: '44%',
                                '& .message__wrapper': {
                                    '& .message__file__wrapper': {
                                        background: '#FFFFFF',
                                        marginRight: '60px',
                                        '& .message__file a': {
                                            '& i': {
                                                background: '#2B2B2B'
                                            },
                                            color: '#2B2B2B'
                                        }
                                    },
                                    '& .message__txt': {
                                        ...fontStyle,
                                        fontWeight: 400,
                                        color: '#2B2B2B',
                                        marginRight: '45px'
                                    },
                                    background: '#FFFFFF',
                                    minWidth: '202px',
                                    '& .message__txt__wrapper': {
                                        background: '#FFFFFF',
                                    },
                                    [`& .message__info__wrapper, + .message__info__wrapper`]: {
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        marginTop: '-25px',
                                        padding: '4px 8px',
                                        '& .message__timestamp': {
                                            ...fontStyle,
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            lineHeight: '6px',
                                            '& + i': {
                                                display: 'none'
                                            }
                                        }
                                    },
                                }
                            },
                            [`& .sender__message__container .message__actions .action__group__react,
                                .sender__message__container .message__actions .action__group_edit,
                                .sender__message__container .message__actions .action__group__trhead,
                                .sender__message__container .message__actions .action__group_translate,
                                .receiver__message__container .message__actions
                            `]: {
                                display: 'none'
                            },
                            '& .sender__message__container > .message__image > .message__info__wrapper': {
                                marginTop: '0px',
                                background: '#FFFFFF'
                            }
                        }
                    }
                }
            }
        }
    },
}))

export default function Message({ userType, userData }) {
    const classes = useStyles();

    return (
        <MessageWrapper container spacing={2}>
            <Grid item xs={12} className={`${classes.mainCont} mainHeading`}>
                <HeadingComponent text={{ "primary": "Messages", "secondary": "A one site view of all your messages with doctors" }} />
            </Grid>
            <Grid item xs={12} className='comet-chat-wrapper'>
                <CometChatConversationListWithMessages cUsertype={userType} userData={userData} />
            </Grid>
        </MessageWrapper>
    )
}