import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HeadingComponent from "../../HeadingComponent";
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";

const Home = () => {
    const classes = useStyles();
    const { userData} = useSelector(state => ({      
        userData: state.auth.userData        
    }))

    return (
        <Grid container spacing={0}>
         <Grid item xs={12} className={classes.mainCont}>
        <HeadingComponent
        navigate = "organisation"
          text={{
            primary: strings.home,
            secondary: strings.opdIpdList,
          }}
        />
      </Grid>
      <Grid item xs={12}>
      <HeadingComponent
        navigate = "organisation"
          text={{
            opd: strings.opd
          }}
        />
      </Grid>
        </Grid>
    )
}

export default Home;