import { Button, Grid } from "@mui/material";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import styled from "styled-components";

export const MainContainer = styled.div`
margin: 24px;
@media (max-width: 640px) {
 margin: 15px;   
}
`;

export const StyledQrCodeGrid = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .image-style {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export const ContainerDiv = styled.div`
width: 730px;
min-height: 640px;
border-radius: 8px;
box-shadow: -4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25);
border-radius: 8px;
// margin: 100px 270px;
overflow: auto;
display: flex-wrap;
@media (max-width: 640px) {
  margin: 0px;
  width: auto;
  height: auto;
  padding-bottom: 10px;
}
`;

export const TopSection = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 12px 24px;
color: #5E5E5E;
border-bottom: 2px solid #F5F5F7 ;
`;

export const HeadingText = styled.div`
font-family: Poppins;
font-size: 24px;
font-weight: 700;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #5E5E5E;
`;

export const SecondaryContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 423px;
margin-top: 24px;
padding: 8px ;
border-radius: 8px;
gap: 8px;
@media (max-width: 640px) {
  margin:10px 0px;
  width: 300px;
}
`;

export const DateContainer = styled.div`
display: flex;
align-item: center;
justify-content: space-between;
width: 393px;
height: 85px;
margin-top: 10px;
padding: 10px 42px 10px 16px;
border-radius: 8px;
background: linear-gradient(90deg, rgba(9, 126, 183, 0.2) 0%, rgba(42, 171, 108, 0.2) 100%);m   
@media (max-width: 640px) {
  margin:10px 0px;
  width: 340px;
}
`;

export const SecondHeadingText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #5E5E5E;
  margin-top: 16px;
`;

export const ContentText = styled.div`
font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: left;
`;

export const StyledImg = styled.img`
width: 24px;
height: 24px; `;

export const ButtonContainer = styled.div`
text-align: center;
margin-top: 10px;
`;

export const ButtonStyled = styled(Button)`
padding: 12px 112px 12px 112px;
border-radius: 8px;
gap: 8px;
color: #fffff;
`;

export const checkStyle = {
  "& .MuiFormControlLabel-label": {
    fontFamily: " Poppins",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#5E5E5E"
  }
}

export const StyledDatePicker = styled(DatePicker)`
  && {
    .MuiOutlinedInput-root {
      & fieldset {
        border: none;
      }
      & input {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        padding-top: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #5E5E5E;
        width: 40%;
      }
    }
    .MuiSvgIcon-root {
      font-size: 24px; 
      color: green;    
    }
    .MuiFormLabel-root {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      color: #5E5E5E;
      margin-left: 0px;

    }
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  && {
    .MuiOutlinedInput-root {
      & fieldset {
        border: none;
      }
      & input {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5E5E5E;
        width: 40%;
        padding-top: 10px;
      }
    }
    .MuiSvgIcon-root {
      font-size: 24px; 
      color: green;  
    }
    .MuiFormLabel-root {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #5E5E5E;
      min-width: 100px;
    }
    .MuiFormControl-root{
      border: 2px solid;
      color: red;
    }
    
  }
`;