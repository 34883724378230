import { Formik } from "formik";
import { Grid, Box, CircularProgress, Divider } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './loginModalStyle.css';
import { ForgetPasswordText, ForgetPasswordWrapper } from './styles';
import { useStyles } from '../../assets/styles/LoginStyles';
import strings from '../../constant/strings';
import LogoIcon from '../Common/LogoIcon';
import RadioButtonBox1 from './RadioButtonBox1';
import { userList } from '../../constant/staticLists';
import { forgotPasswordSchema, loginSchema } from '../../utils/forms/schema.loginSchema';
import { StyledButton, StyledCrossIcon, StyledFormik, StyledImageCont, StyledLogo, StyledMainModalCont, StyledMargin, StyledPadding, StyledText } from '../../assets/styles/CommonStyles';
import { useDispatch, useSelector } from "react-redux";
import { doctorLoginAction, loginAction, organizationLoginAction } from "../../redux/auth/authActions";
import FieldWrapper from "../InputField/TextFieldWrapper";
import * as authService from "../../services/authService.js";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

const LoginModal1 = ({ isSignUpModal, setLoginToggle, loginToggle, toggleLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useSelector(state => ({
    error: state.auth.error
  }))
  const history = useHistory();
  const [activeRadio, setActiveRadio] = useState('');
  const [radioProced, setRadioProced] = useState('');
  const [mobile, setMobile] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isForgetPassword, setForgetPassword] = useState(false);

  const handleSignupButton = useCallback(() => {
    toggleLogin();
    setMobile(false);
  }, [mobile]);
console.log('activeRadio', activeRadio)
  const renderLoginApi = async (values) => {
    localStorage.setItem("userType", activeRadio)
    const passData = { email: values.email, password: values.password, loginType: 2 }
    if (activeRadio === "patient") {
      dispatch(loginAction(passData));
    } 
    else if(activeRadio === "doctor"){
      dispatch(doctorLoginAction(passData));
    }
    else if(activeRadio === "organization"){
      dispatch(organizationLoginAction(passData));
    }

    setTimeout(() => {
      setButtonLoading(false);
    }, 2000);
  }

  // const renderActiveLink = () => {
  //   switch (activeRadio) {
  //     case 'normaluser': return (
  //       localStorage.setItem("linkValue", 'normaluser')
  //     );
  //     case 'doctor': return (
  //       localStorage.setItem("linkValue", 'doctor')
  //     )
  //     case 'clinicians': return (
  //       // history.push('/organization')
  //       localStorage.setItem("linkValue", 'clinicians')

  //     )
  //     case 'subadmin': return (
  //       // history.push('/subuser')
  //       localStorage.setItem("linkValue", 'subadmin')
  //     )
  //     default:
  //       break;
  //   }
  // }

  // useEffect(() => {
  //   renderActiveLink()
  // }, [activeRadio])

  // useEffect(() => {
  //   localStorage.setItem("linkValue", '')
  //   localStorage.setItem("background", false)
  // }, [])

  useEffect(() => {
    setActiveRadio('');
    setRadioProced('');
  }, [isSignUpModal])

  const handleSubmit = (values) => {
    setButtonLoading(true);
    renderLoginApi(values);
  }


  const handleForgetPasswordSubmit = async (values) => {
    setButtonLoading(true);
    const res = await authService.forgotPasswordAPI({ email: values.email, forgotPasswordType: 2 });
    if (res.response_type === "fail") {
      setButtonLoading(false);
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    } else {
      setButtonLoading(false);
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
    }
  }

  const handleProceed = () => {
    setRadioProced(activeRadio)
  };

  const handleBackButton = useCallback(() => {
    setRadioProced('');
    setActiveRadio('');
    setMobile(false);
    setForgetPassword(false);
  }, [mobile, isForgetPassword, activeRadio, radioProced]);

  const handleCrossButton = () => {
    document.body.style.overflowY = 'auto'
    setLoginToggle(loginToggle => !loginToggle);
    setActiveRadio('');
    setRadioProced('');
    setMobile(false);
    setForgetPassword(false);
  }

  const renderSubmitButton = () => isForgetPassword ? 'Forget password' : 'Log In';

  const handleActiveRadio = (value) => {
    sessionStorage.setItem("userType", value)
    setActiveRadio(value);
  }

  return (
    <StyledMainModalCont>
      <StyledImageCont>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={handleCrossButton}
        />
      </StyledImageCont>
      <StyledPadding>
        <div className={classes.logoCont}>
        <img
          src={"/assets/img/image1.png"}
          alt="User"
          className={classes.logoStyle}
        /></div>
        {radioProced === '' ?
          <>
            <div className={classes.mainTextStyle}>
              {strings.loginAs}
            </div>
            <div className={classes.radioStyle}>
              <RadioButtonBox1
                list={userList}
                activeRadio={activeRadio}
                setActiveRadio={handleActiveRadio}
                handleProceed={handleProceed}
              />
            </div> </> :
          <div>
            <Formik
              initialValues={{
                email: "",
                password: ""
              }}
              validationSchema={isForgetPassword ? forgotPasswordSchema : loginSchema}
              onSubmit={(values) => isForgetPassword ? handleForgetPasswordSubmit(values) : handleSubmit(values)}
            >
              {({ errors, handleSubmit }) => (
                <StyledFormik>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="email"
                        label={`${strings.emailAddress}`}
                        type="email"
                      />
                    </Grid>
                    {!isForgetPassword ? <Grid item xs={12}>
                      <FieldWrapper
                        name="password"
                        label={`${strings.password}`}
                      />
                    </Grid> : null
                    }
                  </Grid>
                  {activeRadio !== 'subadmin' && <ForgetPasswordWrapper check={!isForgetPassword}>
                    <ForgetPasswordText onClick={() => setForgetPassword(true)}>{strings.forgottenPassword}</ForgetPasswordText>
                  </ForgetPasswordWrapper>}
                  <Box m={1} pt={1}>
                    <StyledButton
                      name="submit"
                      disabled={Object.keys(errors).length > 0 || buttonLoading}
                      onClick={handleSubmit}
                      variant="contained">
                      {error === null && buttonLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={20}
                          thickness={5}
                        />
                      ) : renderSubmitButton()}
                    </StyledButton>
                  </Box>
                </StyledFormik>
              )}
            </Formik>
            <Divider classes={{ root: classes.strikeStyle }}>{strings.or}</Divider>
          </div>
        }
        <StyledMargin>
          {!isForgetPassword ? (
            <StyledText className={classes.textStyle}>{strings.dontHaveAnAccount}{' '}
              <span className="sign-color" onClick={handleSignupButton}>{strings.signUp}</span></StyledText>
          ) : (
            <StyledText className={classes.textStyle}>{strings.haveAnAccount}{' '}
              <span className="sign-color" onClick={handleBackButton}>{strings.signInNow}</span></StyledText>
          )}
        </StyledMargin>
      </StyledPadding>
    </StyledMainModalCont>
  )
}

export default LoginModal1;