import { createAction } from "@reduxjs/toolkit";

export const getPatientAppointmentAction = createAction('appointment/list',(payload) => {
    return {
      payload: payload,
    };
  });

  export const getDoctorListAction = createAction('appointment/doctor/list',(payload) => {
    return {
      payload: payload,
    };
  });

  export const getDoctorSlotsAction = createAction('appointment/doctor/slots',(payload) => {
    return {
      payload: payload,
    };
  });

  export const patientInput = createAction('appointment/patient/inputs',(payload) => {
    return {
      payload: payload,
    };
  });

  export const cancelAppointmentAction = createAction('appointment/patient/cancel',(payload) => {
    return {
      payload: payload,
    };
  });