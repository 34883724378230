import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// import your reducers
import authReducer from '../auth/authSlice';
import userReducer from '../user/userSlice';
import routerRducer from '../router/routerSlice';
import appointmentReducer from '../appointment/patient/patientAppointmentSlice';
import scheduleReducer from '../schedule/doctor/doctorScheduleSlice';
import publicReducer from '../public/publicSlice';
import doctorReducer from '../user/doctor/doctorSlice';
import rootSaga from './rootSaga';
import doctorAppointmentReducer from '../appointment/doctor/doctorAppointmentSlice';

// create a history object
export const history = createBrowserHistory();

// create a root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  router: routerRducer,
  appointment: appointmentReducer,
  doctorAppointment: doctorAppointmentReducer,
  schedule: scheduleReducer,
  public: publicReducer,
  doctor:doctorReducer
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'user', 'appointment', 'doctorAppointment'], // only persist the slices you want to persist
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sagaMiddleware
  ],
});

// run the root saga
sagaMiddleware.run(rootSaga);

export default store;
