import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import Home from "../../components/ui/Organisation/Dashboard/index";

const OrganisationDashboard = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <Home />
      </AppLayout>
    </Grid>
  )
}

export default OrganisationDashboard;