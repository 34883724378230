import { makeStyles } from "@mui/styles";
import colors from "../../../../../../constant/colors";
import fontWeights from "../../../../../../constant/fontWeights";
import { flexbox } from "@mui/system";

const useStyles = makeStyles(() => ({
    
    radioContainer: {
        marginTop: "16px",
        border: `2px solid ${colors.black2}`,
        borderRadius: "8px",
        justifyContent: "center",
        padding: "8px"   
    },
    
    selectedRadio :{
        marginTop: "16px",
        border: "2px solid green",
        borderRadius: "8px",
        justifyContent: "center",
        padding: "8px", 
    },
    
    radioText: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        /* or 150% */
        textAlign: "center",
        color:` ${colors.black2}`,
    },
    
    selectedLabel : {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        /* or 150% */
        textAlign: "center",
        color:` ${colors.black2}`
    }
}));

export { useStyles };
