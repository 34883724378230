import { apiRequest } from "./service";
import { LOGIN, SIGNUP, FORGOT_PASSWORD, GET_PATIENT_PROFILE, UPDATE_PATIENT_PROFILE, LOGOUT, DOCTOR_LOGIN, UPDATE_PASSWORD, VERIFY_TOKEN ,DOCTOR_LOGOUT, ORGANIZATION_LOGIN} from "./apiUrl";

export const forgotPasswordAPI = (payload) => {
  return apiRequest({
    apiUrl: FORGOT_PASSWORD,
    method: "POST",
    payload,
  });
}

export const updatePasswordAPI = (payload) => {
  const {token, password} = payload;
  return apiRequest({
    apiUrl: `${UPDATE_PASSWORD}${token}`,
    method: "PUT",
    payload: password,
  });
}

export const verifyTokenAPI = (payload) => {
  return apiRequest({
    apiUrl: `${VERIFY_TOKEN}${payload}`,
    method: "GET",  
  });
}

export const loginApi = (payload) =>
  apiRequest({
    apiUrl: LOGIN,
    method: "POST",
    payload,
  });

export const doctorloginApi = (payload) =>
  apiRequest({
    apiUrl: DOCTOR_LOGIN,
    method: "POST",
    payload,
  });

  export const organizationloginApi = (payload) =>
  apiRequest({
    apiUrl: ORGANIZATION_LOGIN,
    method: "POST",
    payload,
  });

export const signupApi = (payload) =>
  apiRequest({
    apiUrl: SIGNUP,
    method: "POST",
    payload,
  });

export const getProfile = (payload) =>
  apiRequest({
    apiUrl: GET_PATIENT_PROFILE,
    method: "GET",
    token: payload.token,
  });

export const updateProfile = (payload, token) => {
  return apiRequest({
    apiUrl: UPDATE_PATIENT_PROFILE,
    method: "PUT",
    payload,
    token
  });
}

export const logout = (token) => {
  return apiRequest({
    apiUrl: `${LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}

export const doctorlogout = (token) => {
  return apiRequest({
    apiUrl: `${DOCTOR_LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}