const v1 = "api/v1/";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
export const getUriWithDurationAndDateFormat = (uri, duration = 'w', date) => {
  if (duration) uri += `&duration=${duration.toLowerCase()}`
  return `${uri}&date=${date || formattedDate}`
}

let user = "patient";
const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

function getApiUrl() {
  user = sessionStorage.getItem("userType");
  return `${apiUrl}${v1}${user}`;
}

export const LOGIN = `${apiUrl}${v1}${user}/login`;
export const LOGIN1 = `${apiUrl}${v1}${user}/login`;
export const LOGOUT = `${apiUrl}${v1}${user}/logout`;
export const DOCTOR_LOGIN = `${apiUrl}${v1}doctor/sign-in`;
export const ORGANIZATION_LOGIN=`${apiUrl}${v1}organization/sign-in`;
export const SIGNUP = `${apiUrl}${v1}${user}/signup`;
export const FORGOT_PASSWORD = `${apiUrl}${v1}${user}/forgotpassword`;
export const GET_BLOOD_SUGAR = `${apiUrl}${v1}${user}/get-blood-sugar?timezone=${timezone}`;
export const GET_HEALTH_PARAMS = `${apiUrl}${v1}${user}/get-health-params?timezone=${timezone}`;
export const GET_BODY_TEMPERATURE = `${apiUrl}${v1}${user}/get-body-temperature?timezone=${timezone}`;
export const GET_BLOOD_PRESSURE = `${apiUrl}${v1}${user}/get-blood-pressure?timezone=${timezone}`;
export const GET_HEART_RATE = `${apiUrl}${v1}${user}/get-heart-rate?timezone=${timezone}`;
export const GET_LIPID_DATA = getUriWithDurationAndDateFormat(`${apiUrl}${v1}${user}/get-lipid?timezone=${timezone}`, 'w');
export const GET_RESPIRATORY_RATE = `${apiUrl}${v1}${user}/get-respiratory-rate?timezone=${timezone}`;
export const GET_OXYGEN = `${apiUrl}${v1}${user}/get-spo2?timezone=${timezone}`;
export const ADD_RESPIRATORY_RATE = `${apiUrl}${v1}${user}/add-respiratory-rate`;
export const ADD_HEART_RATE = `${apiUrl}${v1}${user}/add-heart-rate`;
export const ADD_BLOOD_PRESSURE = `${apiUrl}${v1}${user}/add-blood-pressure`;
export const ADD_BLOOD_SUGAR = `${apiUrl}${v1}${user}/add-blood-sugar`;
export const ADD_BLOOD_OXYGEN = `${apiUrl}${v1}${user}/add-spo2`;
export const ADD_BODY_TEMPERATURE = `${apiUrl}${v1}${user}/add-body-temperature`;
export const GET_PATIENT_PROFILE = `${apiUrl}${v1}${user}/get-profile`;
export const UPDATE_PATIENT_PROFILE = `${apiUrl}${v1}${user}/update-patient`;
export const ADD_LIPID_PROFILE = `${apiUrl}${v1}${user}/add-lipid`;
export const ADD_MEDICAL_REPORT = `${apiUrl}${v1}${user}/add-medical-report`;
export const DEL_MEDICAL_REPORT = `${apiUrl}${v1}${user}/delete-medical-report`;
export const GET_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/get-health-condition`;
export const ADD_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/add-health-condition`;
export const UPDATE_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/update-health-condition`;
export const DEL_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/delete-health-condition`;
export const GET_MEDICATIONS = `${apiUrl}${v1}${user}/get-medication`;
export const UPDATE_MEDICATIONS = `${apiUrl}${v1}${user}/update-medication`;
export const ADD_MEDICATIONS = `${apiUrl}${v1}${user}/add-medication`;
export const DEL_MEDICATIONS = `${apiUrl}${v1}${user}/delete-medication`;
export const GET_SURGURIES = `${apiUrl}${v1}${user}/get-surgery`;
export const UPDATE_SURGURIES = `${apiUrl}${v1}${user}/update-surgery`;
export const ADD_SURGURIES = `${apiUrl}${v1}${user}/add-surgery`;
export const DEL_SURGURIES = `${apiUrl}${v1}${user}/delete-surgery`;
export const GET_ALLERGY = `${apiUrl}${v1}${user}/get-allergy`;
export const UPDATE_ALLERGY = `${apiUrl}${v1}${user}/update-allergy`;
export const ADD_ALLERGY = `${apiUrl}${v1}${user}/add-allergy`;
export const DEL_ALLERGY = `${apiUrl}${v1}${user}/delete-allergy`;
export const GET_VACCINATIONS = `${apiUrl}${v1}${user}/get-vaccination`;
export const UPDATE_VACCINATIONS = `${apiUrl}${v1}${user}/update-vaccination`;
export const ADD_VACCINATIONS = `${apiUrl}${v1}${user}/add-vaccination`;
export const DEL_VACCINATIONS = `${apiUrl}${v1}${user}/delete-vaccination`;
export const QRCODE = `${apiUrl}${v1}${user}`;
export const QRPATIENTINFO = `${apiUrl}${v1}public/patient-info?`;
export const GET_PATIENT_APPOINTMENT_LIST = `${apiUrl}${v1}${user}/appointments`;
export const GET_DOCTOR_LIST = `${apiUrl}${v1}${user}/get-doctor`;
export const UPDATE_PASSWORD = `${apiUrl}${v1}${user}/update-password?token=`;
export const SLOTS = `${apiUrl}${v1}${user}/get-doctor-available-slots?timezone=${timezone}&doctorId=`;
export const CREATE_APPOINTMENT = `${apiUrl}${v1}${user}/create-appointment`;
export const VERIFY_TOKEN = `${apiUrl}${v1}${user}/verify-password-reset-token?token=`;
export const BILLING_INVOICE = `${apiUrl}${v1}${user}/get-invoices`;
export const BILLING_INVOICE_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-invoices`;
export const BILLING_INVOICE_DETAIL = `${apiUrl}${v1}${user}/get-invoice`;
export const GET_APPOINTMENT_FOR_DOCTOR = `${apiUrl}${v1}doctor/appointments`;
export const GET_PATIENT_DETAILS_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-patient-details`;
export const GET_DOCTOR_LIPID_DATA = getUriWithDurationAndDateFormat(`${apiUrl}${v1}doctor/get-patient-lipid?timezone=${timezone}`, 'y');
export const GET_DOCTOR_BLOOD_SUGAR = `${apiUrl}${v1}doctor/get-patient-blood-sugar?timezone=${timezone}`;
export const GET_DOCTOR_HEALTH_PARAMS = `${apiUrl}${v1}doctor/get-patient-health-params?timezone=${timezone}`;
export const GET_DOCTOR_BODY_TEMPERATURE = `${apiUrl}${v1}doctor/get-patient-body-temperature?timezone=${timezone}`;
export const GET_DOCTOR_BLOOD_PRESSURE = `${apiUrl}${v1}doctor/get-patient-blood-pressure?timezone=${timezone}`;
export const GET_DOCTOR_HEART_RATE = `${apiUrl}${v1}doctor/get-patient-heart-rate?timezone=${timezone}`;
export const GET_DOCTOR_RESPIRATORY_RATE = `${apiUrl}${v1}doctor/get-patient-respiratory-rate?timezone=${timezone}`;
export const GET_DOCTOR_OXYGEN = `${apiUrl}${v1}doctor/get-patient-spo2?timezone=${timezone}`;
export const GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-patient-previous-appointment?timezone=${timezone}&duration=y`;
export const DOCTOR_LOGOUT = `${apiUrl}${v1}doctor/logout`;
export const GET_CURRENCY = `${apiUrl}${v1}public/get-currencies`;
export const GET_DOCTOR_AVAILABLE_TIME = `${apiUrl}${v1}doctor/available-time?timezone=${timezone}`;
export const DOCTOR_ADD_SLOT=`${apiUrl}${v1}doctor/add-slots`;
export const GET_SLOT_BY_DOCTOR=`${apiUrl}${v1}doctor/slots?timezone=${timezone}`;
export const DELETE_SLOT_BY_DOCTOR=`${apiUrl}${v1}doctor/delete-slot?`;
export const SEND_MEDICATION_BY_DOCTOR=`${apiUrl}${v1}doctor/add-eprescription`;
export const CANCEL_APPOINTMENT_BY_PATIENT=`${apiUrl}${v1}patient/cancel-appointment`;
export const CONFIRM_APPOINTMENT_BY_DOCTOR=`${apiUrl}${v1}doctor/confirm-appointment`;
export const PATIENT_CHECKOUT= `${apiUrl}${v1}patient/appointment/checkout`;
export const GET_PROFILE_BY_DOCTOR= `${apiUrl}${v1}doctor/get-profile`;
export const UPDATE_PROFILE_BY_DOCTOR= `${apiUrl}${v1}doctor/update-profile`;
export const GET_MEDICAL_REPORT = `${apiUrl}${v1}${user}/get-medical-report`;
export const GET_PATIENT_MEDICAL_REPORT=`${apiUrl}${v1}doctor/get-patient-medical-reports?timezone=${timezone}&duration=y`;
export const GET_MEDICAL_DETAILS_BY_PATIENT = `${apiUrl}${v1}patient/get-medical-detailes`;
export const GET_MEDICAL_DETAILS_BY_DOCTOR = `${apiUrl}${v1}doctor/get-patient-medical-detailes?duration=y&timezone=${timezone}`;
export const GET_PATIENT_PRESCRIPTION_BY_DOCTOR=`${apiUrl}${v1}doctor/get-patient-prescriptions?timezone=${timezone}&duration=y`;
export const GET_PATIENT_EPRESCRIPTION = `${apiUrl}${v1}patient/get-prescriptions`;
