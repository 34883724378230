import React from "react";
import { useState } from "react";
import { StyledCrossIcon, StyledDivider, StyledFormik, StyledImageCont, StyledMargin, StyledText, StyledSecondaryButton, ButtonImage, StyledMainModalCont } from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from '@mui/material';
import { Formik } from "formik";
import PhoneField from "../../../PhoneField";
import CountryField from "../../../CountryField";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import { oneSpace, textString } from "../../../../utils/TextTransform";
import { doctorSignUpSchema } from "../../../../utils/forms/schema.doctorSignUpSchema";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import Page1 from "../Doctor/Page1";
import DoctorSignUpSteps from "./commonComponents/DoctorSignUpSteps";
import "../NormalUser/style.css"


const Doctor = ({ isSignUpModal, setSignUpModal, toggleSignUp, handleSubmit = {}, buttonLoading }) => {
  const [orgUser, setOrgUser] = useState({ orgName: '', name: '', phoneNumber: '', email: '', countryCode: '', address: '', country: '' });
  const [pages, setPageNavigation] = useState({ first: true, second: false, third: false, forth: false, fifth: false });
  const classes = useStyles();
  let initialValues = { orgName: '', phoneNumber: '', email: '', countryCode: '', address: '', country: '' }
  const handleNavigation = () => {
    setPageNavigation((page) => ({ ...page, first: false, second: true }))

  }
  return (
    isSignUpModal && pages.first ?
      <StyledMainModalCont visible={isSignUpModal}>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setSignUpModal(false)}
          />
        </StyledImageCont>
        <LogoIcon />

        <DoctorSignUpSteps
          image1={"/assets/img/halfFilledImg1.png"}
          image2={"/assets/img/step2Img.png"}
          image3={"/assets/img/step3Img.png"}
          image4={"/assets/img/step4Img.png"}
          lineStyle={[0, 0, 0]}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={doctorSignUpSchema}
          onSubmit={() => handleSubmit(orgUser, buttonLoading)}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            values,
            touched,
            setTouched,
            handleSubmit,
          }) => (
            <StyledFormik>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="orgName"
                    type="text"
                    placeholder={`${strings.orgName}`}
                  // value={orgUser.orgName}
                  // onChange={e => {
                  //   handleChange(e);
                  //   setOrgUser((user) => ({ ...user, orgName: textString(e.target.value.split('').filter(oneSpace).join('')) }));
                  // }}
                  // onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldWrapper
                    name="fullName"
                    type="text"
                    placeholder={`${strings.fullName}`}
                  // value={orgUser.name}
                  // onChange={e => {
                  //   handleChange(e);
                  //   setOrgUser((user) => ({ ...user, name: textString(e.target.value.split('').filter(oneSpace).join('')) }));
                  // }}
                  // onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FieldWrapper
                    name="email"
                    placeholder={`${strings.emailAddress}`}
                    type="email"
                  // value={orgUser.email}
                  // onChange={e => {
                  //   handleChange(e);
                  //   setOrgUser((user) => ({ ...user, email: textString(e.target.value.split('').filter(oneSpace).join('')) }));
                  // }}
                  // onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={6}>
                  <PhoneField
                    value={orgUser.phoneNumber}
                    name="phoneNumber"
                    onChange={(e, value) => {
                      handleChange("phoneNumber")(e);
                      values.phoneNumber = e;
                      values.countryCode = `+${value.dialCode}`
                      // setOrgUser((user) => ({ ...user, phoneNumber: e, countryCode: `+${value.dialCode}` }))
                    }}
                    onBlur={() => setTouched({ ...touched, phoneNumber: true })}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldWrapper
                    name="address"
                    type="text"
                    placeholder={`${strings.address}`}
                  // value={orgUser.address}
                  // onChange={e => {
                  //   handleChange(e);
                  //   setOrgUser((user) => ({ ...user, address: textString(e.target.value.split('').filter(oneSpace).join('')) }));
                  // }}
                  // onBlur={handleBlur}
                  // errors={errors}
                  />

                </Grid>

                <Grid item xs={12} >

                  <CountryField
                    name="country"
                    value={orgUser.country}
                    placeholder={`${strings.country}`}
                    onChange={(e) => {
                      values.country = e
                    }}
                    onBlur={() => setTouched({ ...touched, country: true })}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
              </Grid>
              <Box m={1} pt={1} >
                <div className={classes.buttonContainerRight}>
                  <StyledSecondaryButton
                    disabled={Object.keys(errors).length < 0 || buttonLoading}
                    onClick={handleNavigation}
                    variant="contained">
                    {buttonLoading ? (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={20}
                        thickness={5}
                      />
                    ) :
                      (<ButtonImage
                        src={"/assets/img/forward.png"}
                        alt="forwardButton"
                      ></ButtonImage>)}

                  </StyledSecondaryButton>
                </div>
              </Box>
            </StyledFormik>
          )}
        </Formik>
        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>{strings.alreadyAccount}{' '}
            <span className="sign-color" onClick={toggleSignUp}>{strings.login}</span></StyledText>
        </StyledMargin>
      </StyledMainModalCont> :
      <Page1
        isSignUpModal={isSignUpModal}
        setSignUpModal={setSignUpModal}
        pages={pages}
        setPageNavigation={setPageNavigation}
        buttonLoading={buttonLoading}
        toggleSignUp={toggleSignUp}
      />


  )
}


export default Doctor;