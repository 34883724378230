import React from 'react'


export const DoctorSignUpSteps = ({image1, image2, image3, image4, lineStyle}) => {
  return (
    <div style={{marginTop: "30px"}}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={image1} alt="Image 1" style={{ marginRight: '10px', width: "32px", height: "32px" }} />
      <div style={{ flex: '1', borderBottom: lineStyle[0] ? '2px solid black' : '2px dashed black' , marginRight: '10px' }}></div>
      <img src={image2} alt="Image 2" style={{ marginRight: '10px', width: "32px", height: "32px" }} />
      <div style={{ flex: '1',borderBottom: lineStyle[1] ? '2px solid black' : '2px dashed black', marginRight: '10px'  }}></div>
      <img src={image3} alt="Image 1" style={{ marginRight: '10px', width: "32px", height: "32px" }} />
      <div style={{ flex: '1', borderBottom: lineStyle[2] ? '2px solid black' : '2px dashed black', marginRight: '10px' }}></div>
      <img src={image4} alt="Image 1" style={{ marginRight: '10px', width: "32px", height: "32px" }} />
    </div> 
    </div>
  )
}

export default DoctorSignUpSteps;