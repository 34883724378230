import React from "react";
import { StyledContainer } from "../../../assets/styles/WebsiteLinks";
import { Typography } from "@mui/material";

function PrivacyPolicy() {
  return (
    <StyledContainer>
      <Typography variant="h3" className="centered">
        Privacy policy
      </Typography>
      <Typography variant="p">
        Emed4u Private Limited henceforth referred as (‘Emed4u’) is committed to
        protecting the privacy and security of your personal data. The
        protection of your privacy in the processing of your personal data is an
        important concern to which we pay special attention in our business
        processes. We process personal data collected during visits to our
        websites according to the legal provisions valid for the countries in
        which the websites are maintained.
        <br />
        <br />
        Emed4u website and app ( “Website” /“App” respectively), respects your
        privacy, and seeks to comply with applicable legal requirements in
        respect of data collection, processing and transfer. This privacy notice
        describes the information about you that EMED4U collects through this
        website, how that information is used, maintained, shared, protected and
        how you can update it. It also applies to all personal data received by
        EMED4U from the European Economic Area (“EEA”) in any format, including
        electronic or paper. It is effective on the date posted below and
        applies to our use of your information post effective date.
      </Typography>

      <ol className="multilevel">
        <li>
          <Typography variant="h6">
            <strong>{`WHY THIS PRIVACY POLICY?`}</strong>
          </Typography>
          <br />
          <Typography variant="p">
            This Privacy Policy is published in compliance with, inter alia:
            <br />
            <ul>
              <li> Section 43A of the Information Technology Act, 2000;</li>
              <li>
                Regulation 4 of the Information Technology (Reasonable Security
                Practices and Procedures and
              </li>
              <li>
                Sensitive Personal Information) Rules, 2011 (the “SPI Rules”);
              </li>
              <li>
                Regulation 3(1) of the Information Technology (Intermediaries
                Guidelines) Rules, 2011.
              </li>
              <li>This Privacy Policy states the following:</li>
              <li>
                The type of information collected from the Users, including
                Personal Information and Sensitive Personal Data or Information
                relating to an individual;
              </li>
              <li>
                The purpose, means and modes of collection, usage, processing,
                retention and destruction of such information; and
              </li>
              <li>How and to whom Emed4u will disclose such information.</li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`PERSONAL DATA THAT WE COLLECT`}</strong>
          </Typography>
          <br />
          <Typography variant="p">
            Personal data refers to data that lets EMED4U know the specifics of
            who you are and which may be used to identify, contact or locate you
            but not limited to the following:
            <br />
            <ul>
              <li>
                Contact information: Name, Address, Contact details, Email ID,
                Phone Number;
              </li>
              <li>
                Demographic information: Gender, Age, Date of Birth; Marital
                Status, Nationality.
              </li>
              <li>
                Data regarding your usage of the services such as search history
                and history of the appointments made by you through the use of
                Services;
              </li>
              <li>
                Any other detail relating to the above as voluntarily provided
                to Emed4u by you, for providing value added service;
              </li>
              <li>
                Medical records and history which you voluntarily provide or is
                generated on usage of any of the services availed by you from
                any entity in the Emed4u;
              </li>
              <li>
                Physical, physiological and mental health condition which you
                voluntarily provide or is generated on usage of our services
                through the app/website;
              </li>
              <li>
                Data regarding your medical qualifications, registrations and
                certifications;
              </li>
              <li>
                Any additional information that you voluntarily choose to
                provide to Emed4u 24*7 through any mode of communication or
                during any interaction.
              </li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`WHERE DO WE COLLECT YOUR DATA FROM?`}</strong>
          </Typography>
          <Typography variant="p">
            For end users:
            <br />
            <ul>
              <li>
                Any information that you voluntarily choose to provide to us
                through app, website, email, during interaction with us on call
                or chat and other modes of communication;Information that we
                collect from healthcare service provider (HSPs) such as doctors,
                hospitals, etc. in the Emed4u, to whom you have permitted the
                sharing of your personal information;
              </li>
              <li>
                Data you have provided to any group company of the Company,
                affiliates, associates, subsidiary, holding company of the
                Company, associates and subsidiaries of holding company of the
                Company, to whom you have given consent for sharing of such
                information.
              </li>
            </ul>
            For doctors:
            <br />
            <ul>
              <li>
                Before onboarding you, we may request information regarding your
                qualifications, experience, public profile, and representations
                made by you to us;
              </li>
              <li>
                During your usage of the App / Website, we may collect
                information based on your use of the Services;
              </li>
              <li>
                We may collect other information that you voluntarily choose to
                provide to us through app, website, email, during interaction
                with us on call or chat and other modes of communication;
              </li>
              <li>
                We may collect information from any group company, affiliates,
                associates, subsidiary, holding company of the Company,
                associates and subsidiaries of holding company of the Company to
                whom you have given consent for sharing of information for
                availing value added service.
              </li>
            </ul>
            You hereby consent to the collection of such information by Emed4u.
            Without prejudice to the generality of the above, information
            collected by us from you may include (but is not limited to) the
            following:
            <ul>
              <li>
                contact data (such as your email address and phone number);
              </li>
              <li>
                demographic data (such as your gender, your date of birth and
                your pin code);
              </li>
              <li>
                data regarding your usage of the services and history of the
                appointments made by or with you through the use of Services;
              </li>
              <li>
                insurance data (such as your insurance carrier and insurance
                plan);
              </li>
              <li>
                other information that you voluntarily choose to provide to us
                (such as information shared by you with us through emails or
                letters).
              </li>
            </ul>
            The information collected from you by Emed4u may constitute
            ‘personal information’ or ‘sensitive personal data or information’
            under the SPI Rules.
            <br />
            <br />
            “Personal Information” is defined under the SPI Rules to mean any
            information that relates to a natural person, which, either directly
            or indirectly, in combination with other information available or
            likely to be available to a body corporate, is capable of
            identifying such person.
            <br />
            <br />
            The SPI Rules further define “Sensitive Personal Data or
            Information” of a person to mean personal information about that
            person relating to:
            <ul>
              <li>passwords;</li>
              <li>
                financial information such as bank accounts, credit and debit
                card details or other payment instrument details;
              </li>
              <li>physical, physiological and mental health condition;</li>
              <li> sexual orientation;</li>
              <li> medical records and history;</li>
              <li> biometric information;</li>
              <li>
                information received by body corporate under lawful contract or
                otherwise;
              </li>
              <li>
                visitor details as provided at the time of registration or
                thereafter; and
              </li>
              <li>call data records.</li>
            </ul>
            <br />
            Emed4u will be free to use, collect and disclose information that is
            freely available in the public domain without your consent.
            <br />
            <br />
            EMED4U websites may contain links to other websites. EMED4U shall
            not be responsible for the privacy notices or the content of such
            websites even if:
            <br />
            <ul>
              <li>
                You accessed the third party website using links from our
                website; or
              </li>
              <li>You linked to our website from a third party website.</li>
            </ul>
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`HOW WE USE YOUR PERSONAL DATA`}</strong>
          </Typography>
          <Typography variant="p">
            We can only use your personal data if we have a proper reason for
            doing so. We only use your data for one or more of these reasons:
            <br />
            <br />
            General (end users and doctors):
            <br />
            <br />
            <ul>
              <li>
                Your registration for the purpose of receiving our Services,
                identification, communication, notification and for fulfilment
                of the Terms and Conditions [Insert link of terms and
                conditions];
              </li>
              <li>
                Analysis, research, training and disclosure (where required) to
                its affiliates, agents and government authorities.
              </li>
              <li>
                Offering you personalized Services and targeted advertisements
                of various healthcare and wellness plans and offering you
                customised health insights;
              </li>
              <li>Improvement of our products and services;</li>
              <li>
                Research and analysis for the development of commercial
                solutions;
              </li>
              <li>
                Addressing your requests, queries and complaints, in any,
                pertaining to our Services; other customer care related
                activities;
              </li>
              <li>Investigating, enforcing, and resolving disputes;</li>
              <li>
                For the purpose of contacting you to provide you with
                information on new Services and offers, taking feedback,
                assisting you with completion of transactions or other issues
                relating to the use of Services;
              </li>
              <li>
                To send notices, communications, alerts, new offers relevant to
                use of the Services offered by Emed4u 24*7 on Website/app;
              </li>
              <li>
                Carrying out our obligations in relation to any agreement with
                our business partners or contractors;
              </li>
              <li>
                Technical administration and customization of Website, and other
                general administrative and business purposes;
              </li>
              <li>
                To send you information about special promotions or offers
                (either offered by Emed4u 24*7 or by its business partners), new
                features or products both of the Apollo as well as third-party
                offers or products with whom Emed4u 24*7 has a tie-up;
              </li>
            </ul>
            <br />
            For end users only:
            <br />
            <br />
            <ul>
              <li>
                Creation and maintenance of health records in electronic form in
                the Personal Health Record (PHR) database;
              </li>
              <li>
                For sharing with your chosen HSP in the Emed4u like doctors,
                hospitals, diagnostic centres, chemists who may provide you
                services under the Emed4u 24*7 Platform.
              </li>
              <li>Processing any orders you may place using our Services;</li>
              <li>For doctors only:</li>
              <li>
                For verifying your professional credentials and any
                representations you have made to us;
              </li>
              <li>For processing any payments made to you;</li>
              <li>
                For providing recommendations to end users based on your
                expertise and specialisations;
              </li>
              <li>For providing any other service to you.</li>
              <li>To fulfil a contract we have with you, or</li>
              <li>
                If we have a legal duty to use your data for a particular
                reason, or
              </li>
              <li>When we get your consent to use it, or</li>
              <li>
                When it is in our legitimate interests which are our business or
                commercial reasons for using your data, but even so, we will not
                unfairly put our legitimate interests above what is best for
                you.
              </li>
            </ul>
            <br />
            <br />
            <strong>Marketing and events:</strong> We use personal information
            to deliver marketing and event communications to you across various
            platforms, such as email, telephone, text messaging, direct mail,
            and online. If we send you a marketing email, it will include
            instructions on how to opt out of receiving these emails in the
            future. We also maintain email preference centres for you to manage
            your information and marketing preferences. Please remember that
            even if you opt out of receiving marketing emails, we may still send
            you important service information related to your accounts and
            subscriptions.
            <br />
            <br />
            <strong>Legal obligations:</strong> We may be required to use and
            retain personal information for legal and compliance reasons, such
            as the prevention, detection, or investigation of a crime; loss
            prevention; or fraud. We may also use personal information to meet
            our internal and external audit requirements, information security
            purposes, and as we otherwise believe to be necessary or
            appropriate:
            <br />
            Under applicable law, which may include laws outside your country of
            residence; 
            <br />
            <br />
            To respond to requests from courts, law enforcement agencies,
            regulatory agencies, and other public and government authorities,
            which may include such authorities outside your country of
            residence;
            <br />
            <br />
            <strong>
              We endeavour to collect only such information that is reasonably
              necessary to perform services for you or to respond to your
              inquiries. You are responsible for ensuring that the information
              you provide is accurate, complete and current.
            </strong>
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`HOW WE USE YOUR PERSONAL DATA`}</strong>
          </Typography>
          <Typography variant="p">
            We store your personal information in accordance with applicable
            laws, which means we keep your data for as long as necessary to
            provide you with our Services or as may be required under any law.
            We shall store your personal information for lawful purposes only.
            We keep de-identified data for research and statistical purposes for
            a longer period.
            <br />
            <br />
            If you close your account, we have no obligation to retain your
            data, and we may delete any or all of your data without liability.
            However, we may retain data related to you if we believe it may be
            necessary to prevent fraud or future abuse, or if required by law,
            or for other legitimate purposes. We may continue to store your data
            in anonymised form for analytical and research purposes.
          </Typography>
        </li>
        <br />
        <li>
          <Typography variant="h6">
            <strong>{`PRIVACY STATEMENTS`}</strong>
          </Typography>
          <Typography variant="h6">
            <strong>{`ALL USERS NOTE:`}</strong>
          </Typography>
          <Typography variant="p">
            <ol type="i">
              <li>
                Accordingly, a condition of each User’s use of and access to the
                Services is their acceptance of the Terms of Use, which also
                involves acceptance of the terms of this Privacy Policy. Any
                User that does not agree with any provisions of the same has the
                option to discontinue the Services provided by Emed4u
                immediately.
              </li>
              <li>
                An indicative list of information that Emed4u may require you to
                provide to enable your use of the Services is provided in the
                Schedule annexed to this Privacy Policy.
              </li>
              <li>
                All the information provided to Emed4u by a User, including
                Personal Information or any Sensitive Personal Data or
                Information, is voluntary. You understand that Emed4u may use
                certain information of yours, which has been designated as
                Personal Information or ‘Sensitive Personal Data or Information’
                under the SPI Rules, (a) for the purpose of providing you the
                Services, (b) for commercial purposes and in an aggregated or
                non-personally identifiable form for research, statistical
                analysis and business intelligence purposes, (c) for sale or
                transfer of such research, statistical or intelligence data in
                an aggregated or non-personally identifiable form to third
                parties and affiliates (d) for communication purpose so as to
                provide You a better way of booking appointments and for
                obtaining feedback in relation to the Practitioners and their
                practice, (e) debugging customer support related issues.. (f)
                for the purpose of contacting you to complete any transaction if
                you do not complete a transaction after having provided us with
                your contact information in the course of completing such steps
                that are designed for completion of the transaction. Emed4u also
                reserves the right to use information provided by or about the
                End-User for the following purposes:
                <ul>
                  <li> Publishing such information on the Website.</li>
                  <li>
                    Contacting End-Users for offering new products or services.
                  </li>
                  <li>
                    Contacting End-Users for taking product and Service
                    feedback.
                  </li>
                  <li>
                    Analyzing software usage patterns for improving product
                    design and utility.
                  </li>
                  <li>
                    Analyzing anonymized practice information for commercial
                    use.
                  </li>
                  <li>
                    Processing payment instructions including those through
                    independent third party service providers such as payment
                    gateways, banking and financial institutions, pre-paid
                    instrument and wallet providers for processing of payment
                    transaction or deferral of payment facilities.
                  </li>
                </ul>
              </li>
              <li>
                If you have voluntarily provided your Personal Information to
                Emed4u for any of the purposes stated above, you hereby consent
                to such collection and use of such information by Emed4u.
                However, Emed4u shall not contact You on Your telephone
                number(s) for any purpose including those mentioned in this
                sub-section 4.1(iii), if such telephone number is registered
                with the Do Not Call registry (“DNC Registry”) under the PDPA
                without your express, clear and un-ambiguous written consent.
              </li>
              <li>
                Collection, use and disclosure of information which has been
                designated as Personal Information or Sensitive Personal Data or
                Information’ under the SPI Rules requires your express consent.
                By affirming your assent to this Privacy Policy, you provide
                your consent to such use, collection and disclosure as required
                under applicable law.
              </li>
              <li>
                Emed4u does not control or endorse the content, messages or
                information found in any Services and, therefore, Emed4u
                specifically disclaims any liability with regard to the Services
                and any actions resulting from your participation in any
                Services, and you agree that you waive any claims against Emed4u
                relating to same, and to the extent such waiver may be
                ineffective, you agree to release any claims against Emed4u
                relating to the same.
              </li>
              <li>
                You are responsible for maintaining the accuracy of the
                information you submit to us, such as your contact information
                provided as part of account registration. If your personal
                information changes, you may correct, delete inaccuracies, or
                amend information by making the change on our member information
                page or by contacting us through privacy@Emed4u.com. We will
                make good faith efforts to make requested changes in our then
                active databases as soon as reasonably practicable. If you
                provide any information that is untrue, inaccurate, out of date
                or incomplete (or becomes untrue, inaccurate, out of date or
                incomplete), or Emed4u has reasonable grounds to suspect that
                the information provided by you is untrue, inaccurate, out of
                date or incomplete, Emed4u may, at its sole discretion,
                discontinue the provision of the Services to you. There may be
                circumstances where Emed4u will not correct, delete or update
                your Personal Data, including (a) where the Personal Data is
                opinion data that is kept solely for evaluative purpose; and (b)
                the Personal Data is in documents related to a prosecution if
                all proceedings relating to the prosecution have not been
                completed.
              </li>
              <li>
                If you wish to cancel your account or request that we no longer
                use your information to provide you Services, contact us through
                support@Emed4u.com . We will retain your information for as long
                as your account with the Services is active and as needed to
                provide you the Services. We shall not retain such information
                for longer than is required for the purposes for which the
                information may lawfully be used or is otherwise required under
                any other law for the time being in force. After a period of
                time, your data may be anonymized and aggregated, and then may
                be held by us as long as necessary for us to provide our
                Services effectively, but our use of the anonymized data will be
                solely for analytic purposes. Please note that your withdrawal
                of consent, or cancellation of account may result in Emed4u
                being unable to provide you with its Services or to terminate
                any existing relationship Emed4u may have with you.
              </li>
              <li>
                If you wish to opt-out of receiving non-essential communications
                such as promotional and marketing-related information regarding
                the Services, please send us an email at support@Emed4u.com.
                Emed4u may require the User to pay with a credit card, wire
                transfer, debit card or cheque for Services for which
                subscription amount(s) is/are payable. Emed4u will collect such
                User’s credit card number and/or other financial institution
                information such as bank account numbers and will use that
                information for the billing and payment processes, including but
                not limited to the use and disclosure of such credit card number
                and information to third parties as necessary to complete such
                billing operation. Verification of credit information, however,
                is accomplished solely by the User through the authentication
                process. User’s credit-card/debit card details are transacted
                upon secure sites of approved payment gateways which are
                digitally under encryption, thereby providing the highest
                possible degree of care as per current technology. However,
                Emed4u provides you an option not to save your payment details.
                User is advised, however, that
              </li>
              <li>
                Due to the communications standards on the Internet, when a User
                or the End-User or anyone who visits the Website, Emed4u
                automatically receives the URL of the site from which anyone
                visits. Emed4u also receives the Internet Protocol (IP) address
                of each User’s computer (or the proxy server a User used to
                access the World Wide Web), User’s computer operating system and
                type of web browser the User is using, email patterns, as well
                as the name of User’s ISP. This information is used to analyze
                overall trends to help Emed4u improve its Service. The linkage
                between User’s IP address and User’s personally identifiable
                information is not shared with or disclosed to third parties.
                Notwithstanding the above, Emed4u may share and/or disclose some
                of the aggregate findings (not the specific data) in anonymized
                form (i.e., non-personally identifiable) with advertisers,
                sponsors, investors, strategic partners, and others in order to
                help grow its business.
              </li>
              <li>
                The Website uses temporary cookies to store certain (that is not
                sensitive personal data or information) that is used by Emed4u
                and its service providers for the technical administration of
                the Website, research and development, and for User
                administration. In the course of serving advertisements or
                optimizing services to its Users, Emed4u may allow authorized
                third parties to place or recognize a unique cookie on the
                User’s browser. The cookies however, do not store any Personal
                Information of the User. You may adjust your internet browser to
                disable cookies. If cookies are disabled you may still use the
                Website, but the Website may be limited in the use of some of
                the features.
              </li>
              <li>
                User may have limited access to the Website without creating an
                account on the Website. Unregistered Users can make appointments
                with the doctors by providing their name and phone number. In
                order to have access to all the features and benefits on our
                Website, a User must first create an account on our Website. To
                create an account, a User is required to provide the following
                information, which such User recognizes and expressly
                acknowledges is Personal Information allowing others, including
                Emed4u, to identify the User: name, User ID, email address,
                country, ZIP/postal code, age, phone number, password chosen by
                the User and valid financial account information. Other
                information requested on the registration page, including the
                ability to receive promotional offers from Emed4u, is optional.
                Emed4u may, in future, include other optional requests for
                information from the User to help Emed4u to customize the
                Website to deliver personalized information to the User.
              </li>
              <li>
                This Privacy Policy applies to Services that are owned and
                operated by Emed4u. Emed4u does not exercise control over the
                sites displayed as search results or links from within its
                Services. These other sites may place their own cookies or other
                files on the Users’ computer, collect data or solicit personal
                information from the Users, for which Emed4u is not responsible
                or liable. Accordingly, Emed4u does not make any representations
                concerning the privacy practices or policies of such third
                parties or terms of use of such websites, nor does Emed4u
                guarantee the accuracy, integrity, or quality of the
                information, data, text, software, sound, photographs, graphics,
                videos, messages or other materials available on such websites.
                The inclusion or exclusion does not imply any endorsement by
                Emed4u of the website, the website&#39;s provider, or the
                information on the website. If you decide to visit a third party
                website linked to the Website, you do this entirely at your own
                risk. Emed4u encourages the User to read the privacy policies of
                that website.
              </li>
              <li>
                The Website may enable User to communicate with other Users or
                to post information to be accessed by others, whereupon other
                Users may collect such data. Such Users, including any
                moderators or administrators, are not authorized Emed4u
                representatives or agents, and their opinions or statements do
                not necessarily reflect those of Emed4u, and they are not
                authorized to bind Emed4u to any contract. Emed4u hereby
                expressly disclaims any liability for any reliance or misuse of
                such information that is made available by Users or visitors in
                such a manner.
              </li>
              <li>
                Emed4u does not collect information about the visitors of the
                Website from other sources, such as public records or bodies, or
                private organisations, save and except for the purposes of
                registration of the Users (the collection, use, storage and
                disclosure of which each End User must agree to under the Terms
                of Use in order for Emed4u to effectively render the Services).
              </li>
              <li>
                Emed4u maintains a strict "No-Spam&quot" policy, which means
                that Emed4u does not intend to sell, rent or otherwise give your
                e-mail address to a third party without your consent.
              </li>
              <li>
                Emed4u has implemented best international market practices and
                security policies, rules and technical measures to protect the
                personal data that it has under its control from unauthorised
                access, improper use or disclosure, unauthorised modification
                and unlawful destruction or accidental loss. However, for any
                data loss or theft due to unauthorized access to the User’s
                electronic devices through which the User avails the Services,
                Emed4u shall not be held liable for any loss whatsoever incurred
                by the User.
              </li>
              <li>
                Emed4u implements reasonable security practices and procedures
                and has a comprehensive documented information security
                programme and information security policies that contain
                managerial, technical, operational and physical security control
                measures that are commensurate with respect to the information
                being collected and the nature of Emed4u’s business.
              </li>
              <li>
                Emed4u takes your right to privacy very seriously and other than
                as specifically stated in this Privacy Policy, will only
                disclose your Personal Information in the event it is required
                to do so by law, rule, regulation, law enforcement agency,
                governmental official, legal authority or similar requirements
                or when Emed4u, in its sole discretion, deems it necessary in
                order to protect its rights or the rights of others, to prevent
                harm to persons or property, to fight fraud and credit risk, or
                to enforce or apply the Terms of Use.
              </li>
              <br />
              <Typography variant="h6">
                <strong>{`PRACTITIONERS NOTE:`}</strong>
              </Typography>
              <br />
              <li>This section applies to all Practitioners.</li>
              <li>
                A part of the registration as well as the application creation
                and submission process that is available to Practitioners on
                Emed4u, certain information, including Personal Information or
                Sensitive Personal Data or Information is collected from the
                Practitioners.
              </li>
              <li>
                All the statements in this Privacy Policy apply to all
                Practitioners, and all Practitioners are therefore required to
                read and understand the privacy statements set out herein prior
                to submitting any Personal Information or Sensitive Personal
                Data or Information to Emed4u, failing which they are required
                to leave the Services, including the Website immediately.
              </li>
              <li>
                Practitioners’ personally identifiable information, which they
                choose to provide to Emed4u, is used to help the Practitioners
                describe and identify themselves. This information is
                exclusively owned by Emed4u You will be the owner of your
                information and you consent to Emed4u collecting, using,
                processing and/or disclosing this information for the purposes
                hereinafter stated . Emed4u may use such information for
                commercial purposes and in an aggregated or non-personally
                identifiable form for research, statistical analysis and
                business intelligence purposes, and may sell or otherwise
                transfer such research, statistical or intelligence data in an
                aggregated or non-personally identifiable form to third parties
                and affiliates. Emed4u also reserves the right to use
                information provided by or about the Practitioner for the
                following purposes:
              </li>
              <li>Publishing such information on the Website.</li>
              <li>
                Contacting Practitioners for offering new products or services
                subject to the telephone number registered with the DNC
                Registry.
              </li>
              <li>Contacting Practitioners for taking product feedback.</li>
              <li>
                Analyzing software usage patterns for improving product design
                and utility.
              </li>
              <li>
                Analyzing anonymized practice information including financial,
                and inventory information for commercial use.
              </li>
              <li>
                Emed4u automatically enables the listing of Practitioners’
                information on its Website for every ‘Doctor’ or ‘Clinic’ added
                to a Practice using its software. The Practitioner information
                listed on Website is displayed when End-Users search for doctors
                on Website, and the Practitioner information listed on Website
                is used by End-Users to request for doctor appointments. Any
                personally identifiable information of the Practitioners listed
                on the Website is not generated by Emed4u and is provided to
                Emed4u by Practitioners who wish to enlist themselves on the
                Website, or is collected by Emed4u from the public domain.
                Emed4u displays such information on its Website on an as-is
                basis making no representation or warranty on the accuracy or
                completeness of the information. As such, we strongly encourage
                Practitioners to check the accuracy and completeness of their
                information from time to time, and inform us immediately of any
                discrepancies, changes or updates to such information. Emed4u
                will, however, take reasonable steps to ensure the accuracy and
                completeness of this information.
              </li>
              <li>
                Emed4u may also display information for Practitioners who have
                not signed up or registered for the Services, provided that the
                Practitioners have consented to Emed4u collecting, processing
                and/or disclosing their information on the Website. Such
                Practitioners are verified by Emed4u or its associates, and
                Emed4u makes every effort to capture accurate information for
                such Practitioners. However, Emed4u does not undertake any
                liability for any incorrect or incomplete information appearing
                on the Website for such Practitioners.
              </li>
              <Typography variant="h6">
                <strong>{`END-USERS NOTE:`}</strong>
              </Typography>
              <li>This section applies to all End-Users.</li>
              <li>
                As part of the registration/application creation and submission
                process that is available to End-Users on this Website, certain
                information, including Personal Information or Sensitive
                Personal Data or Information is collected from the End-Users.
              </li>
              <li>
                All the statements in this Privacy Policy apply to all
                End-Users, and all End-Users are therefore required to read and
                understand the privacy statements set out herein prior to
                submitting any Personal Information or Sensitive Personal Data
                or Information to Emed4u, failing which they are required to
                leave the Emed4u immediately.
              </li>
              <li>
                If you have inadvertently submitted any such information to
                Emed4u prior to reading the privacy statements set out herein,
                and you do not agree with the manner in which such information
                is collected, processed, stored, used or disclosed, then you may
                access, modify and delete such information by using options
                provided on the Website. In addition, you can, by sending an
                email to privacy@Emed4u.com, inquire whether Emed4u is in
                possession of your personal data, and you may also require
                Emed4u to delete and destroy all such information.
              </li>
              <li>
                End-Users’ personally identifiable information, which they
                choose to provide on the Website is used to help the End-Users
                describe/identify themselves. Other information that does not
                personally identify the End-Users as an individual, is collected
                by Emed4u from End-Users (such as, patterns of utilization
                described above) and is exclusively owned by Emed4u. Emed4u may
                also use such information in an aggregated or non-personally
                identifiable form for research, statistical analysis and
                business intelligence purposes, and may sell or otherwise
                transfer such research, statistical or intelligence data in an
                aggregated or non-personally identifiable form to third parties
                and affiliates. In particular, Emed4u reserves with it the right
                to use anonymized End-User demographics information and
                anonymized End-User health information for the following
                purposes:
              </li>
              <li>
                {" "}
                Analyzing software usage patterns for improving product design
                and utility.
              </li>
              <li>
                {" "}
                Analyzing such information for research and development of new
                technologies.
              </li>
              <li>
                Using analysis of such information in other commercial product
                offerings of Emed4u.
              </li>
              <li>
                Sharing analysis of such information with third parties for
                commercial use.
              </li>
              <li>
                Emed4u will communicate with the End-Users through email, phone
                and notices posted on the Website or through other means
                available through the service, including text and other forms of
                messaging. The End-Users can change their e-mail and contact
                preferences at any time by logging into their "Account" at
                www.Emed4u.com and changing the account settings.
              </li>
              <li>
                At times, Emed4u conducts a User survey to collect information
                about End-Users'preferences. These surveys are optional and if
                End-Users choose to respond, their responses will be kept
                anonymous. Similarly, Emed4u may offer contests to qualifying
                End-Users in which we ask for contact and demographic
                information such as name, email address and mailing address. The
                demographic information that Emed4u collects in the registration
                process and through surveys is used to help Emed4u improve its
                Services to meet the needs and preferences of End-Users.
              </li>
              <li>
                Emed4u may keep records of electronic communications and
                telephone calls received and made for making appointments or
                other purposes for the purpose of administration of Services,
                customer support, research and development and for better
                listing of Practitioners.
              </li>
              <li>
                All Emed4u employees and data processors, who have access to,
                and are associated with the processing of sensitive personal
                data or information, are obliged to respect the confidentiality
                of every End-Users’ Personal Information or Sensitive Personal
                Data and Information. Emed4u has put in place procedures and
                technologies as per good industry practices and in accordance
                with the applicable laws, to maintain security of all personal
                data from the point of collection to the point of destruction.
                Any third-party data processor to which Emed4u transfers
                Personal Data shall have to agree to comply with those
                procedures and policies, or put in place adequate measures on
                their own.
              </li>
              <li>
                Emed4u may also disclose or transfer End-Users’ personal and
                other information provided by a User, to a third party as part
                of reorganization or a sale of the assets of a Emed4u
                corporation division or company. Any third party to which Emed4u
                transfers or sells its assets to will have the right to continue
                to use the personal and other information that End-Users provide
                to us, in accordance with the Terms of Use
              </li>
              <li>
                To the extent necessary to provide End-Users with the Services,
                Emed4u may provide their Personal Information to third party
                contractors who work on behalf of or with Emed4u to provide
                End-Users with such Services, to help Emed4u communicate with
                End-Users or to maintain the Website or independent third party
                service providers to process payment instructions including
                providing a payment deferral facility to End-Users in relation
                to the Services. These third-party service providers have access
                to information needed to process payments, but may not use it
                for other purposes. Generally these contractors do not have any
                independent right to share this information, however certain
                contractors who provide services on the Website, including the
                providers of online communications services, may use and
                disclose the personal information collected in connection with
                the provision of these Services in accordance with their own
                privacy policies. In such circumstances, you consent to us
                disclosing your Personal Information to contractors, solely for
                the intended purposes only.
              </li>
              <Typography variant="h6">
                <strong>{`CASUAL VISITORS NOTE:`}</strong>
              </Typography>
              <li>
                No sensitive personal data or information is automatically
                collected by Emed4u from any casual visitors of this website,
                who are merely perusing the Website.
              </li>
              <li>
                Nevertheless, certain provisions of this Privacy Policy are
                applicable to even such casual visitors, and such casual
                visitors are also required to read and understand the privacy
                statements set out herein, failing which they are required to
                leave this Website immediately.
              </li>
              <li>
                If you, as a casual visitor, have inadvertently browsed any
                other page of this Website prior to reading the privacy
                statements set out herein, and you do not agree with the manner
                in which such information is obtained, collected, processed,
                stored, used, disclosed or retained, merely quitting this
                browser application should ordinarily clear all temporary
                cookies installed by Emed4u. All visitors, however, are
                encouraged to use the “clear cookies” functionality of their
                browsers to ensure such clearing / deletion, as Emed4u cannot
                guarantee, predict or provide for the behaviour of the equipment
                of all the visitors of the Website.
              </li>
              <li>
                You are not a casual visitor if you have willingly submitted any
                personal data or information to Emed4u through any means,
                including email, post or through the registration process on the
                Website. All such visitors will be deemed to be, and will be
                treated as, Users for the purposes of this Privacy Policy, and
                in which case, all the statements in this Privacy Policy apply
                to such persons.
              </li>
            </ol>
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`WHEN WE SHARE PERSONAL DATA?`}</strong>
          </Typography>
          <Typography variant="p">
            EMED4U shares or discloses personal data when necessary to provide
            services or conduct our business operations. If EMED4U intends to
            transfer your personal data outside, EMED4U shall take steps to
            ensure that your privacy rights continue to be protected and shall
            ensure that adequate safeguards are in place. We do not collect any
            Special Categories of Personal Data about you (this includes details
            about your race or ethnicity, religious or philosophical beliefs,
            sex life, sexual orientation, political opinions, trade union
            membership, information about your health and genetic and biometric
            data). Nor do we collect any information about criminal convictions
            and offences.
            <br />
            <br />
            EMED4U may, with your consent, disclose information about you to
            unaffiliated third-party customers of EMED4U in connection with your
            application for staffing opportunities advertised on this site, or
            in connection with opportunities advertised on a website of one of
            our affiliates.
            <br />
            <br />
            For example-
            <strong>Within EMED4U:</strong> Our businesses around the world are
            supported by a variety of EMED4U teams and functions, and personal
            information will be made available to them if necessary for the
            provision of services, account administration, sales and marketing,
            customer and technical support, and business and product
            development. All of our employees and contractors are required to
            follow our data protection and security policies when handling
            personal data.
            <br />
            <br />
            <strong>Our business partners:</strong> We occasionally collaborate
            with other organizations to deliver co-branded Services, provide
            content, or to host events, conferences, and seminars. As part of
            these arrangements, you may be a customer of both EMED4U and our
            partners, and our partners and we may collect and share information
            about you. EMED4U will handle personal data in accordance with the
            privacy notice.
            <br />
            <br />
            <strong>Our third-party service providers:</strong> We collaborate
            with service providers around the world for any support needed.
            Personal data may be made available to these parties only when
            necessary to fulfil the services they provide to us, such as
            software, system, and platform support; direct marketing services;
            cloud hosting services; advertising; and order fulfilment and
            delivery. Our third-party service providers are not permitted to
            share or use personal data we make available to them for any other
            purpose than to provide services to us.
            <br />
            <br />
            <strong>Third parties for legal reasons:</strong> We will share
            personal data when we believe it is required, such as: To comply
            with legal obligations and respond to requests from government
            agencies, including law enforcement and other public authorities,
            which may include such authorities outside your country of
            residence.
            <br />
            <br />
            In the event of a merger, sale, restructure, acquisition, joint
            venture, assignment, transfer, or other disposition of all or any
            portion of our business, assets, or stock (including in connection
            with any bankruptcy or similar proceedings)
            <br />
            To protect our rights, users, systems, and services.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`WHERE WE STORE AND PROCESS PERSONAL DATA?`}</strong>
          </Typography>
          <Typography variant="p">
            EMED4U as a global organization, we take steps to ensure that the
            information we collect is processed according to this privacy notice
            and the requirements of applicable law wherever the data is located.
            EMED4U has networks, databases, servers, systems, support, and help
            desks located throughout our offices around the world. We
            collaborate with third parties such as cloud hosting services,
            suppliers, and technology support located around the world to serve
            the needs of our business, workforce, and customers. We take
            appropriate steps to ensure that personal data is processed,
            secured, and transferred according to applicable law.
            <br />
            <br />
            EMED4U will not sell or rent your personal data to anyone. In some
            cases such as to provide the product or service you have requested,
            we may need to disclose or transfer your personal data within EMED4U
            or to third parties if required. When we transfer personal
            information to other countries in which applicable laws do not offer
            the same level of data privacy protection as in your home country,
            we take measures to provide an appropriate level of data privacy
            protection. For example, we use approved contractual clauses,
            multiparty data transfer agreements, intragroup agreements, and
            other measures designed to ensure that the recipients of your
            personal information protect it.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`HOW WE SECURE PERSONAL DATA?`}</strong>
          </Typography>
          <Typography variant="p">
            EMED4U uses appropriate technologies and procedures to protect your
            personal data. Our information security policies and procedures are
            closely aligned with widely accepted international standards and are
            reviewed regularly and updated as necessary to meet our business
            needs, changes in technology, and regulatory requirements.
            <br />
            <br />
            For example, Policies and procedures: EMED4U employs reasonable
            technological, physical and operational security procedures intended
            to protect your personal data from loss, misuse, alteration, or
            unintentional destruction. Our security measures are periodically
            reviewed and updated in an effort to provide appropriate security
            for all the data collected about you.
            <br />
            <br />
            We place appropriate restrictions on access to your personal data.
            <br />
            <br />
            We implement appropriate security measures and controls, including
            monitoring and physical measures, to store and transfer data
            securely.
            <br />
            <br />
            We require privacy, information security, and other applicable
            training on a regular basis for our employees and contractors who
            have access to personal data.
            <br />
            <br />
            We take steps to ensure that our employees and contractors operate
            in accordance with our information security policies and procedures
            and any applicable contractual conditions.
            <br />
            <br />
            We require, with contracts and security reviews, our third-party
            vendors and providers to protect any personal data with which they
            are entrusted in accordance with our security policies and
            procedures
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`COOKIES`}</strong>
          </Typography>
          <Typography variant="p">
            From time to time, we may use a standard technology called a
            “cookie”. A cookie is a small text file that is placed on a computer
            or other device and is used to identify the user or device and to
            collect information. Cookies are typically assigned to one of four
            categories, depending on their function and intended purpose:
            necessary cookies, performance cookies, functional cookies, and
            cookies for marketing purposes. A cookie cannot retrieve any other
            data from your hard drive, pass on computer viruses, or capture your
            e-mail address. Currently, websites use cookies to enhance the
            user’s visit; in general, cookies can securely store a user’s ID and
            password, personalize home pages, and identify which parts of a site
            have been visited. It is possible to set your browser to inform you
            when a cookie is being placed. This way you have the opportunity to
            decide whether to accept the cookie. By showing how and when our
            visitors use this web site, this information can help us to improve
            our site on a continual basis.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`CHILDREN UNDER 13 YEARS OF AGE`}</strong>
          </Typography>
          <Typography variant="p">
            We do not provide services directly to children or proactively
            collect their personal information. Parents or guardians may
            authorize children under 13 years old to use the EMED4U site
            provided they assume all responsibility and legal liability for the
            conduct of such child including, without limitation, monitoring the
            child’s access and use of the EMED4U site. 
            <br />
            <br />
            If EMED4U learns that personal data of a person under 13 years old
            has been collected without verifiable parental consent, then EMED4U
            may take the appropriate steps to delete such information. However,
            if you discover your child has submitted his/her data to EMED4U, you
            may request to have such data deleted from EMED4U’s database by
            sending an e-mail request. Upon receiving the request, EMED4U shall
            ensure to delete such information from its database.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`YOUR RIGHTS AND YOUR PERSONAL DATA`}</strong>
          </Typography>
          <Typography variant="p">
            We respect your right to access and control your information, and we
            will respond to requests for information and, where applicable, will
            correct, amend, or delete your personal information. In such cases,
            we will need you to respond with proof of your identity before you
            can exercise these rights. 
            <br />
            <br />
            <strong>The right to access information:</strong> At any point you
            can contact us to request the information we hold on you as well as
            why we have that information, who has access to the information and
            where we obtained the information from. Once we have received your
            request we will respond within one month. There are no fees or
            charges for the first request but additional requests for the same
            data may be subject to an administrative fee.
            <br />
            <br />
            <strong>The right to correct and update the information:</strong>If
            the data we hold on you is out of date, incomplete or incorrect, you
            can inform us and your data will be updated.
            <br />
            <br />
            <strong>The right to have your information erased:</strong>If you
            feel that we should no longer be using your data or that we are
            illegally using your data, you can request that we erase the data we
            hold. When we receive your request we will confirm whether the data
            has been deleted or the reason why it cannot be deleted (for example
            because we need it for our legitimate interests or regulatory
            purpose(s)).
            <br />
            <br />
            <strong>The right to object to processing:</strong>You have the
            right to request that we stop processing your data. Upon receiving
            the request, we will contact you and let you know if we are able to
            comply or if we have legitimate grounds to continue to process your
            data. Even after you exercise your right to object, we may continue
            to hold your data to comply with your other rights or to bring or
            defend legal claims.
            <br />
            <br />
            <strong>The right to data portability:</strong> You have the right
            to request that we transfer some of your data to another controller.
            We will comply with your request, where it is feasible to do so,
            within one month of receiving your request.
            <br />
            <br />
            <strong>The right to withdraw your consent </strong>to the
            processing at any time for any processing of data to which consent
            was sought. You can withdraw your consent easily by telephone,
            email, or by post (refer to consent withdrawal form).
            <br />
            <br />
            <strong>The right to object to the processing </strong>of personal
            data where applicable.
            <br />
            <br />
            <strong>The right to lodge a complaint</strong>   with the Data
            Protection Representative.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{`Changes to Our Privacy notice`}</strong>
          </Typography>
          <Typography variant="p">
            EMED4U may update privacy notice from time to time. We encourage you
            to check our website frequently to see the current privacy notice so
            that you may stay informed of how EMED4U is using and protecting
            your information. Whenever a change to this notice is significant,
            we will place a prominent notice on this website and provide an
            updated effective date.
          </Typography>
        </li>
        <li>
          <Typography variant="h6">
            <strong>{` Questions/Contact Information`}</strong>
          </Typography>
          <Typography variant="p">
            If you have questions or comments regarding this privacy notice,
            please contact us, or at:
            <br />
            Email:  _______________ 
            <br />
            Effective Date:  _____________
          </Typography>
        </li>
      </ol>
    </StyledContainer>
  );
}

export default PrivacyPolicy;
