import { apiRequest, apiRequestPublic } from "./service";
import { ADD_ALLERGY, ADD_BLOOD_OXYGEN, ADD_BLOOD_PRESSURE, ADD_BLOOD_SUGAR, ADD_BODY_TEMPERATURE, ADD_HEALTH_CONDITIONS, ADD_HEART_RATE, ADD_LIPID_PROFILE, ADD_MEDICAL_REPORT, ADD_MEDICATIONS, ADD_RESPIRATORY_RATE, ADD_SURGURIES, ADD_VACCINATIONS, BILLING_INVOICE, BILLING_INVOICE_DETAIL, CREATE_APPOINTMENT, DEL_ALLERGY, DEL_HEALTH_CONDITIONS, DEL_MEDICAL_REPORT, DEL_MEDICATIONS, DEL_SURGURIES, DEL_VACCINATIONS, GET_ALLERGY, GET_APPOINTMENT_FOR_DOCTOR, GET_BLOOD_PRESSURE, GET_BLOOD_SUGAR, GET_BODY_TEMPERATURE, GET_DOCTOR_BLOOD_PRESSURE, GET_DOCTOR_BLOOD_SUGAR, GET_DOCTOR_BODY_TEMPERATURE, GET_DOCTOR_HEALTH_PARAMS, GET_DOCTOR_HEART_RATE, GET_DOCTOR_LIPID_DATA, GET_DOCTOR_AVAILABLE_TIME, GET_DOCTOR_LIST, GET_DOCTOR_OXYGEN, GET_DOCTOR_RESPIRATORY_RATE, GET_HEALTH_CONDITIONS, GET_HEALTH_PARAMS, GET_HEART_RATE, GET_LIPID_DATA, GET_MEDICAL_REPORT, GET_MEDICATIONS, GET_OXYGEN, GET_PATIENT_DETAILS_FOR_DOCTOR, GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_DOCTOR, GET_PATIENT_APPOINTMENT_LIST, GET_RESPIRATORY_RATE, GET_SURGURIES, GET_VACCINATIONS, QRCODE, QRPATIENTINFO, SLOTS, UPDATE_ALLERGY, UPDATE_HEALTH_CONDITIONS, UPDATE_MEDICATIONS, UPDATE_SURGURIES, UPDATE_VACCINATIONS, getUriWithDurationAndDateFormat, GET_MEDICAL_DETAILS_BY_DOCTOR, GET_PATIENT_MEDICAL_REPORT, GET_PATIENT_PRESCRIPTION, DOCTOR_ADD_SLOT, GET_SLOT_BY_DOCTOR, DELETE_SLOT_BY_DOCTOR, GET_CURRENCY, SEND_MEDICATION_BY_DOCTOR, CANCEL_APPOINTMENT_BY_PATIENT, CONFIRM_APPOINTMENT_BY_DOCTOR, GET_PATIENT_EPRESCRIPTION, GET_MEDICAL_DETAILS_BY_PATIENT, PATIENT_CHECKOUT, GET_PROFILE_BY_DOCTOR, UPDATE_PROFILE_BY_DOCTOR, GET_PATIENT_PRESCRIPTION_BY_DOCTOR, BILLING_INVOICE_BY_DOCTOR, BILLING_INVOICE_FOR_DOCTOR } from "./apiUrl";

let user = localStorage.getItem("userType")

export const bloodSugarAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_BLOOD_SUGAR, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_BLOOD_SUGAR}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
};

export const healthParamsAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_HEALTH_PARAMS, payload?.duration?.toLowerCase(), payload?.date)}` :
    `${GET_DOCTOR_HEALTH_PARAMS}&appointmentId=${payload?.appointmentId}&duration=${payload?.duration?.toLowerCase()}&date=${payload?.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
};

export const bloodPressureAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_BLOOD_PRESSURE, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_BLOOD_PRESSURE}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
}

export const bloodOxygenAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_OXYGEN, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_OXYGEN}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
}

export const bodyTempAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_BODY_TEMPERATURE, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_BODY_TEMPERATURE}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
}

export const heartRateAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_HEART_RATE, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_HEART_RATE}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
}

export const respiratoryRateAPI = (payload) => {
  const userType = localStorage.getItem("userType");
  let URL = userType === 'patient' ?
    `${getUriWithDurationAndDateFormat(GET_RESPIRATORY_RATE, payload.duration.toLowerCase(), payload.date)}` :
    `${GET_DOCTOR_RESPIRATORY_RATE}&appointmentId=${payload.appointmentId}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
}

export const lipidProfileApi = (payload) => {
  const userType = localStorage.getItem("userType");
  let url = userType === 'patient' ? GET_LIPID_DATA : `${GET_DOCTOR_LIPID_DATA}&appointmentId=${payload.appointmentId}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token
  })
}

export const addRespiratoryRateApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_RESPIRATORY_RATE,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addBloodPressureApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_BLOOD_PRESSURE,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addBloodSugarApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_BLOOD_SUGAR,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addBloodOxygenApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_BLOOD_OXYGEN,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addBodyTemperatureApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_BODY_TEMPERATURE,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addHeartRateApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_HEART_RATE,
    method: "POST",
    token: payload.token,
    payload: rest
  })
}

export const addLipidProfileApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_LIPID_PROFILE,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const addMedicalReport = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_MEDICAL_REPORT,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const getMedicalReport = (payload) => {
  let url = user === 'patient' ? GET_MEDICAL_REPORT : `${GET_PATIENT_MEDICAL_REPORT}&date=${payload.date}&appointmentId=${payload.appointmentId}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token,
  });
}

export const getPatientPrescriptionApi = (payload) => {
  let url = user === 'patient' ? GET_PATIENT_EPRESCRIPTION : `${GET_PATIENT_PRESCRIPTION_BY_DOCTOR}&appointmentId=${payload.appointmentId}&date=${payload.date}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token,
  })
};

// export const getPatientMedicalReportApi = (payload) => {
//   let url = `${GET_PATIENT_MEDICAL_REPORT}&appointmentId=${payload.appointmentId}&date=${payload.date}`;
//   return apiRequest({
//     apiUrl: url,
//     method: "GET",
//     token: payload.token,
//   })
// }

export const deleteMedicalReport = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_MEDICAL_REPORT}/${id}`,
    method: "DELETE",
    token
  })
}

// health conditions apis
export const addHealthCondition = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_HEALTH_CONDITIONS,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const updateHealthCondition = (id, payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: `${UPDATE_HEALTH_CONDITIONS}/${id}`,
    method: "PUT",
    token: payload.token,
    payload: rest.values
  })
}

export const getHealthCondition = (payload) =>
  apiRequest({
    apiUrl: GET_HEALTH_CONDITIONS,
    method: "GET",
    token: payload.token,
  });


export const deleteHealthCondition = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_HEALTH_CONDITIONS}/${id}`,
    method: "DELETE",
    token
  })
}

// medications apis
export const addMedication = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_MEDICATIONS,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const updateMeications = (id, payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: `${UPDATE_MEDICATIONS}/${id}`,
    method: "PUT",
    token: payload.token,
    payload: rest.values
  })
}

export const getMedication = (payload) =>
  apiRequest({
    apiUrl: GET_MEDICATIONS,
    method: "GET",
    token: payload.token,
  });


export const deleteMedication = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_MEDICATIONS}/${id}`,
    method: "DELETE",
    token
  })
}

// surguries apis
export const addSurgury = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_SURGURIES,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const updateSurgury = (id, payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: `${UPDATE_SURGURIES}/${id}`,
    method: "PUT",
    token: payload.token,
    payload: rest.values
  })
}

export const getSurgury = (payload) =>
  apiRequest({
    apiUrl: GET_SURGURIES,
    method: "GET",
    token: payload.token,
  });


export const deleteSurgury = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_SURGURIES}/${id}`,
    method: "DELETE",
    token
  })
}


// allergies apis
export const addAllergy = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_ALLERGY,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const updateAllergy = (id, payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: `${UPDATE_ALLERGY}/${id}`,
    method: "PUT",
    token: payload.token,
    payload: rest.values
  })
}

export const getAllergy = (payload) =>
  apiRequest({
    apiUrl: GET_ALLERGY,
    method: "GET",
    token: payload.token,
  });


export const deleteAllergy = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_ALLERGY}/${id}`,
    method: "DELETE",
    token
  })
}


// Vaccinations apis
export const addVaccinations = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: ADD_VACCINATIONS,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const updateVaccinations = (id, payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: `${UPDATE_VACCINATIONS}/${id}`,
    method: "PUT",
    token: payload.token,
    payload: rest.values
  })
}

export const getVaccinations = (payload) =>
  apiRequest({
    apiUrl: GET_VACCINATIONS,
    method: "GET",
    token: payload.token,
  });

export const deleteVaccinations = (payload) => {
  const { token, id } = payload;
  return apiRequest({
    apiUrl: `${DEL_VACCINATIONS}/${id}`,
    method: "DELETE",
    token
  })
}

export const getPatientAppointment = (payload) => {
  const { status, token } = payload;
  let url = `${GET_PATIENT_APPOINTMENT_LIST}?status=${status}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: token,
  })
};

export const getDoctorlistApi = (payload) => {
  return apiRequest({
    apiUrl: GET_DOCTOR_LIST,
    method: "GET",
    token: payload.token,
  })
};

export const qrcodeAPI = (payload) => {
  const url = `${QRCODE}/encrypt-data?expiresIn=${payload.duration}`;
  return apiRequest({
    apiUrl: url,
    method: "POST",
    token: payload.token,
    payload: payload.data
  });
};

export const qrcodeDetailPageAPI = (payload) => {
  const payrole = `${QRPATIENTINFO}${payload}`;
  return apiRequestPublic({
    apiUrl: payrole,
    method: "GET",
  });
}


export const slotListAPI = (payload) => {
  const url = `${SLOTS}${payload.id}&date=${payload.date}&type=${payload.type}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token
  });
}

export const createAppointmentApi = (payload) => {
  const payrole = `${CREATE_APPOINTMENT}`;
  return apiRequest({
    apiUrl: payrole,
    method: "POST",
    token: payload.token,
    payload: payload.data
  });
}
// invoice list
export const getInvoiceListApi = (payload) => {
  let url = user === 'patient' ?  BILLING_INVOICE :  BILLING_INVOICE_FOR_DOCTOR
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token,
  })
};
// single invoice
export const getInvoiceDetailApi = (payload) => {
  let url = `${BILLING_INVOICE_DETAIL}?invoiceId=${payload.id}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token,
  })
};

export const getDoctorAppointmentApi = (payload) => {
  const { status, token } = payload;
  let url = `${GET_APPOINTMENT_FOR_DOCTOR}?status=${status}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: token,
  })
};

export const getPatientDetailsForDoctorApi = (payload) => {
  const { id, token } = payload;
  let url = `${GET_PATIENT_DETAILS_FOR_DOCTOR}?id=${id}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: token,
  })
};
export const getPatientPreviousAppointmentForDoctorApi = (payload) => {
  let url = `${GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_DOCTOR}&appointmentId=${payload.appointmentId}&date=${payload.date}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token,
  })
};


// export const getPatientEprescriptionApi = (payload) => {
//   return apiRequest({
//     apiUrl: GET_PATIENT_EPRESCRIPTION,
//     method: "GET",
//     token: payload.token,
//   })
// };


export const getDoctorAvailableTimeApi = (payload) => {
  const url = `${GET_DOCTOR_AVAILABLE_TIME}&date=${payload.date}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token
  });
}
export const addSlotDoctorApi = (payload) => {
  const { token, data } = payload;
  return apiRequest({
    apiUrl: DOCTOR_ADD_SLOT,
    method: "POST",
    token: payload.token,
    payload: payload.data
  });
}
export const getSlotsByDoctorApi = (payload) => {
  const url = `${GET_SLOT_BY_DOCTOR}&duration=${payload.duration.toLowerCase()}&date=${payload.date}`;
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token
  });
}

export const deleteSlotsByDoctorApi = (payload) => {
  const url = `${DELETE_SLOT_BY_DOCTOR}slotId=${payload.slot_id}`;
  return apiRequest({
    apiUrl: url,
    method: "DELETE",
    token: payload.token
  });
}

export const getCurrencyApi = () => {
  return apiRequest({
    apiUrl: GET_CURRENCY,
    method: "GET",
   // token: payload.token
  });
}

export const getMedicalHistoryAPI = (payload) => {
  let url = user === 'patient' ?  GET_MEDICAL_DETAILS_BY_PATIENT :  `${GET_MEDICAL_DETAILS_BY_DOCTOR}&date=${payload.date}&appointmentId=${payload.appointmentId}`
  return apiRequest({
    apiUrl: url,
    method: "GET",
    token: payload.token
  });
}

export const getSendMedicationApi = (payload) => {
  const { token, ...rest } = payload;
  return apiRequest({
    apiUrl: SEND_MEDICATION_BY_DOCTOR,
    method: "POST",
    token: payload.token,
    payload: rest.values
  })
}

export const cancelAppointmentByPatientApi = (payload) =>
  apiRequest({
    apiUrl: CANCEL_APPOINTMENT_BY_PATIENT,
    method: "PUT",
    token: payload.token,
    payload: payload.data
  })

export const confirmAppointmentApi = (payload) => {
  const { token, data } = payload;
  return apiRequest({
    apiUrl: CONFIRM_APPOINTMENT_BY_DOCTOR,
    method: "POST",
    token: token,
    payload: data
  });
}

export const patientCheckoutApi = (payload) => {
   return apiRequest({
    apiUrl: PATIENT_CHECKOUT,
    method: "POST",
    token: payload.token,
    payload: payload.data
  });
}
export const getProfileByDoctorAPI = (payload) => {
   return apiRequest({
    apiUrl: GET_PROFILE_BY_DOCTOR,
    method: "GET",
    token: payload
  });
}
export const updateProfileByDoctorAPI = (payload) =>{
return apiRequest({
    apiUrl: UPDATE_PROFILE_BY_DOCTOR,
    method: "PUT",
    token: payload.token,
    payload: payload.data
  })
}
  