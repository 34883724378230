import React , {useState} from 'react';
import { Modal, Wrapper } from "../styles";
import { StyledCrossIcon,StyledImageCont, StyledSecondaryButton, StyledDivider, StyledMargin, StyledText, ButtonImage, DeptHeading, StyledMainModalCont} from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import { Grid, Box, CircularProgress } from '@mui/material';
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import Page3 from "../Doctor/Page3";
import { PDData } from "../../CommonSignUpModal/staticData"
import DeptCheckBox from './commonComponents/DeptCheckBox.js';
import DoctorSignUpSteps from './commonComponents/DoctorSignUpSteps';
import {ranges} from "../../CommonSignUpModal/staticData";
import RadioButtonBox from './commonComponents/RadioButtonBox.js';




const data = [
  { id: 1, label: 'Option 1' },
  { id: 2, label: 'Option 2' },
  { id: 3, label: 'Option 3' },
];


const Page2 = ({pages, setPageNavigation, buttonLoading, isSignUpModal, setSignUpModal, toggleSignUp}) => {
  const classes = useStyles();


  const handleForward=()=>{
    setPageNavigation((page)=>({...page, third: false, forth: true}))
  }
  const handleBack=()=>{
    setPageNavigation((page)=>({...page, third: false, second: true}))
  }

  return (
    (pages.third)  ?
    <StyledMainModalCont>
        <StyledImageCont>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={()=>setSignUpModal(false)}
          />
        </StyledImageCont>
        <LogoIcon />
        
        <DoctorSignUpSteps
        image1={"/assets/img/fullFilledImg1.png"}
        image2 = {"/assets/img/fullFilledImg2.png"}
        image3= {"/assets/img/halfFilledImg3.png"}
        image4 = {"/assets/img/step4Img.png"}
        lineStyle={[1,1,0]} 
        />
        <StyledMargin>
          <StyledText style={{textAlign:"left"}}>{strings.selectDepartment}
          <span style={{fontSize:"12px", lineHeight: "24px"}}> {strings.selectBoth}
            </span></StyledText>
        </StyledMargin>

        <DeptCheckBox data={PDData} />

        <StyledMargin>
          <StyledText style={{textAlign:"left"}}>{strings.selectRange}
          </StyledText>
        </StyledMargin>

        <RadioButtonBox data={ranges} />

        <Grid container spacing={2} padding="20px 0 0 0">
          <Grid item xs={6}>
          <div className={classes.buttonContainerLeft}>
                <StyledSecondaryButton
                  disabled={ buttonLoading}
                  variant="contained"
                  onClick={handleBack}>
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : (<ButtonImage
                    src={"/assets/img/back.png"}
                    alt= "backButton"
                    ></ButtonImage>)}

                </StyledSecondaryButton>
                </div>

          </Grid>
          <Grid item xs={6}>
          <div className={classes.buttonContainerRight}>
                <StyledSecondaryButton
                  disabled={ buttonLoading}
                  variant="contained"
                  onClick={handleForward}>
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : (<ButtonImage
                    src={"/assets/img/forward.png"}
                    alt= "forwardButton"
                    ></ButtonImage>)}

                </StyledSecondaryButton>
                </div>

          </Grid>
        </Grid>

        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>{strings.alreadyAccount}{' '}
            <span className="sign-color" onClick={toggleSignUp}>{strings.login}</span></StyledText>
        </StyledMargin>
        
        
      </StyledMainModalCont> : <Page3
    isSignUpModal={isSignUpModal}
    pages={pages}
    setPageNavigation={setPageNavigation}
    setSignUpModal={setSignUpModal}
    buttonLoading={buttonLoading}
    toggleSignUp={toggleSignUp} />
  )
}

export default Page2;